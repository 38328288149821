import ApiService from "../utils/useAxios"

const api = new ApiService()
export class TeamService {
  getTeamSummary() {
    const endPoint = "/api/Event/TeamSummaryDetails"
    return api.findAll(endPoint)
  }
  getTeamDetails() {
    const endPoint = "/api/Event/GetTeamGridData"
    return api.findAll(endPoint)
  }

  getTeamPersonsSummary(userId) {
    const endPoint = `/api/Location/UserSummaryDetails/${userId}`
    return api
      .findAll(endPoint)
      .then((resp: any) => {
        return resp
      })
      .catch(() => [])
  }
}
