export const PATH = {
  HOME: "/",
  CLOSE_INVENTORY: "/closeInventory",
  PRODUCT: "/product",
  LOGIN: "/login",
  COUNT: "/count",
  PERSONS_VIEW: "/audit/:name/:userId",
  OUT_OF_RANGE_TAGS: "/count/outofrangetags",
  TAG_RANGE_PAGE: "/count/:rangeId",
  TAG_DETAILS: "/count/:rangeName/:locationValue",
  REPORTS: "/reports",
  REPORTS_DETAILS: "/reports/:report_name",
  PAGE_NOT_FOUND: "/PageNotFound",
  AUDIT: "/audit",
  AUDIT_TAG_DETAILS: "/audit/:locationValue",
  PERSON_TAG_DETAILS: "/audit/:name/:userId/:locationValue",
  TEAM: "/team",
  TEAM_PERSONS_VIEW: "/team/:name/:userId",
  TEAM_TAG_DETAILS: "/team/:name/:userId/:locationValue",
  DUPLICATE_TAGS_REVIEW: "/count/duplicateTags/:locationValue/:duplicateId",
  DUPLICATE_TAGS: "/count/duplicateTags",
  VOIDED_AND_COUNTED: "/count/voidedandcounted",
  REPORT_DRILL_DOWN_DETAILS: "/reports/drill-down/details/:locationValue",
  VARIANCE: "/variance",
  VARIANCE_UPLOAD_FILE: "/variance/uploadFile",
  CREATE_VARIANCE: "/variance/createVariance",
  RESEARCH_VARIANCE: "/variance/createVariance/:batchValue",
  RESEARCH_VARIANCE_TAG_DETAILS:
    "/variance/createVariance/:batchValue/:rangeName/:locationValue",
  VARIANCE_UPLOAD_DETAILS: "/variance/uploadDetails",
  TAG_DETAILS_HISTORY: "/count/:rangeName/:locationValue/:inventoryId",
  TEAM_TAG_DETAILS_HISTORY: "/team/:name/:userId/:locationValue/:inventoryId",
  AUDIT_TAG_DETAILS_HISTORY:
    "/audit/:locationValue/tagDetails/history/:inventoryId",
  PERSON_TAG_DETAILS_HISTORY:
    "/audit/:name/:userId/:locationValue/tagDetails/history/:inventoryId",
  RESEARCH_VARIANCE_TAG_DETAILS_HISTORY:
    "/variance/createVariance/:batchValue/:rangeName/:locationValue/:inventoryId",
  ADJUSTMENT_SUMMARY_REPORT: "/report/adjustmentSummaryReport",
  RESET_THE_INVENTORY: "/reset-the-inventory",
  CUSTOMER_SATISFACTION_SURVEY: "/customerSatisfactionSurvey",
  CREATE_TAGS_BULK: "/create/tags/bulk"
}
