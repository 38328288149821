import { RadixParameter } from "src/utils/constants"
import ApiService from "../utils/useAxios"
//import { localTimeAsUTC } from "src/utils/helper"

let localTimeAsUTC: any

async function getLocalTimeAsUTC() {
  if (!localTimeAsUTC) {
    // Import encrypt only when needed
    const { localTimeAsUTC: importedLocalTimeAsUTC } = await import(
      "src/utils/helper"
    )
    localTimeAsUTC = importedLocalTimeAsUTC
  }
  return localTimeAsUTC
}
const api = new ApiService()

export class CountService {
  getAllCounts() {
    const endPoint = "/api/Range/GetRangeDetails"
    return api.findAll(endPoint)
  }
  addTagRange(payload: any) {
    const endPoint = "/api/Range"
    return api
      .post(endPoint, payload)
      .then((res: any) => {
        return res
      })
      .catch((err: any) => err)
  }

  TagaddToRange(payload: any) {
    const endPoint = "api/Location/TagAddToRange"
    return api
      .post(endPoint, payload)
      .then((res: any) => {
        return res
      })
      .catch((err: any) => err)
  }

  DeleteTag(payload: any) {
    const endPoint = "api/Location/DeleteTag"
    return api.post(endPoint, payload)
  }
  DeleteCurrentCount(payload: any) {
    const endPoint = "/api/Location/DeleteLocationInstance"
    return api.post(endPoint, payload)
  }
  DeleteNewCount(payload: any) {
    const endPoint = "/api/Location/DeleteDuplicateLocationInstance"
    return api.post(endPoint, payload)
  }
  MergeNewCount(payload: any) {
    const endPoint = "/api/Location/MergeDuplicateTag"
    return api.post(endPoint, payload)
  }
  EditNewCount(payload: any) {
    const endPoint = "/api/Location/EditDuplicateLocation"
    return api.post(endPoint, payload)
  }

  getOORTagsList() {
    const endPoint = "/api/Location/GetOutOfRangeLocations"
    return api.findAll(endPoint)
  }
  getTagCategory() {
    const endPoint = "/api/Category/GetCategories"
    return api.findAll(endPoint)
  }
  getCountSuccess() {
    const endPoint = "/api/Count/GetCountCompleteStatus"
    return api.findAll(endPoint)
  }
  getOORTagsDetails() {
    const endPoint = "/api/Location/GetOutOfRangeTagDetails"
    return api
      .findAll(endPoint)
      .then((resp: any) => {
        return resp
      })
      .catch(() => ({}))
  }

  getDuplicateTags() {
    const endPoint = `/api/Location/DuplicateInstancesData`
    return api
      .findAll(endPoint)
      .then((resp: any) => {
        return resp
      })
      .catch(() => [])
  }
  getDuplicateTagsReview(_dupLocationInsId, _locationInsId) {
    const endPoint = `/api/Location/GetDuplicateTagDetails`
    return api.post(endPoint, {
      duplicateInstanceId: _dupLocationInsId,
      originalInstanceIds: _locationInsId
    })
  }
  getTagsRanges(rangeId) {
    const endPoint = `/api/Location/GetLocationsByRange/${rangeId}`
    return api
      .findAll(endPoint)
      .then((resp: any) => {
        return resp
      })
      .catch(() => [])
  }
  getCategoryRanges(categoryId) {
    const endPoint = `api/Range/GetCategoryRanges/${categoryId}`
    return api
      .findAll(endPoint)
      .then((resp: any) => {
        return resp
      })
      .catch(() => [])
  }

  DeleteTagRange(_rangeId, _confirmDel) {
    const endPoint = `/api/Range/DeleteTagRange`
    return api.post(endPoint, {
      rangeId: _rangeId,
      confirmDel: _confirmDel
    })
  }

  deleteTagDetails(_tagDetails) {
    const endPoint = `/api/Location/DeleteItem/${_tagDetails}`
    return api
      .delete(endPoint, {})
      .then((resp: any) => {
        return resp
      })
      .catch(() => [])
  }
  AddBulkRecord(changeRecords: any, locationValue, editSource) {
    const endPoint = `/api/ChangeRecord/AddChangeRecordDetail`
    return api.post(endPoint, { changeRecords, locationValue, editSource })
  }
  deleteMultiRecords(inventoryIds: any) {
    const endPoint = `/api/Location/DeleteInventoryDataItems`
    return api
      .delete(endPoint, { data: { inventoryIds } })
      .then((resp: any) => {
        return resp
      })
      .catch(() => [])
  }

  getTagDetails(locationValue) {
    const endPoint = `/api/Location/GetTagDetails/${locationValue}`
    return api
      .findAll(endPoint)
      .then((resp: any) => {
        return resp
      })
      .catch(() => [])
  }
  updateTagRange(payload: any, rangeId: string) {
    const endPoint = `/api/Range/${rangeId}`
    return api.update(endPoint, payload)
  }
  getReports() {
    const endPoint = "api/Reports"
    return api.findAll(endPoint)
  }
  getOutputFiles() {
    const endPoint = "api/OutputFiles"
    return api.findAll(endPoint)
  }
  async runReports(reportId, userPrompts) {
    const localTimeAsUTCFunction = await getLocalTimeAsUTC()
    const currentDateTime = localTimeAsUTCFunction()
    const endPoint = `api/Reports/RunReport`
    let params = {
      reportId: parseInt(reportId, RadixParameter),
      printDate: currentDateTime,
      userPrompts: userPrompts
    }
    return api.post(endPoint, params)
  }
  getPrompts() {
    const endPoint = "/api/Reports/Prompts"
    return api.findAll(endPoint)
  }
  getOutputFileDetails(reportId) {
    const endPoint = `api/OutputFiles/RunOutput/${reportId}`
    return api.findAll(endPoint)
  }
  getConfiguration() {
    const endPoint: string = `api/Location/configuration`
    return api.findAll(endPoint)
  }
  getEventDetail() {
    const endPoint = `api/Event/GetEventDetails`
    return api.findAll(endPoint)
  }
  getConfig() {
    const endPoint = `api/Location/GetConfiguration`
    return api.findAll(endPoint)
  }

  async closeInventory(config) {
    const localTimeAsUTCFunction = await getLocalTimeAsUTC()
    const currentDateTime = localTimeAsUTCFunction()
    const endPoint = `api/Event/CloseInventory?dateTime=${currentDateTime}`
    return api.saveWithParams(endPoint, {}, config)
  }
  async GenerateFinalReports(config) {
    const localTimeAsUTCFunction = await getLocalTimeAsUTC()
    const currentDateTime = localTimeAsUTCFunction()
    const endPoint = `api/Event/GenerateFinalReports?dateTime=${currentDateTime}`
    return api.saveWithParams(endPoint, {}, config)
  }
  UnlockInventory(config) {
    const endPoint = `api/Event/UnlockInventory`
    return api.saveWithParams(endPoint, {}, config)
  }
  TeamEfficiency() {
    const endPoint = "api/TeamEfficiency/GetTeamEfficiency"
    return api.findAll(endPoint)
  }
  auditProgress() {
    const endPoint = "api/TeamEfficiency/GetAuditProgress"
    return api.findAll(endPoint)
  }
  getVoidBannerDetails() {
    const endPoint = "/api/Count/GetVoidedAndCountedTags"
    return api.findAll(endPoint)
  }
  getDepartmentAndRangeDetails() {
    const endPoint = "/api/Reports/DepartmentAndRangeDetails"
    return api.findAll(endPoint)
  }
  updateTag(payload) {
    const endPoint = "/api/Location/EditLocation"
    return api.post(endPoint, payload)
  }
  VoidLocation(payload) {
    const endPoint = "/api/Location/VoidLocation"
    return api.post(endPoint, payload)
  }
  addBypassCloseOutRestriction(payload) {
    const endPoint = "/api/Count/AddBypassCloseOutRestriction"
    return api.post(endPoint, payload)
  }
  getTagDetailsRecordHistory(idInventoryData) {
    const endPoint = "/api/Location/GetItemRecordEditHistory"
    return api.post(endPoint, idInventoryData)
  }
  getTagDetailsHistory(locationValue) {
    const endPoint = `/api/Location/GetTagRecordEditHistory?locationValue=${locationValue}`
    return api
      .findAll(endPoint)
      .then((resp: any) => {
        return resp
      })
      .catch(() => [])
  }
  getTagEditDetails(locationValue) {
    const endPoint = `/api/Location/GetEditHistoryRecords?locationValue=${locationValue}`
    return api
      .findAll(endPoint)
      .then((resp: any) => {
        return resp
      })
      .catch(() => [])
  }
  async getAdjustmentSummaryReport() {
    const localTimeAsUTCFunction = await getLocalTimeAsUTC()
    const currentDateTime = localTimeAsUTCFunction()
    const endPoint = `/api/Reports/AdjustmentSummaryReport?dateTime=${currentDateTime}`
    return api
      .findAll(endPoint)
      .then((resp: any) => {
        return resp
      })
      .catch(() => [])
  }
  getViewProcedurePdf() {
    const endPoint = "/api/OutputFiles/ViewProcedurePdf"
    return api.findAll(endPoint)
  }
  downloadOutputBundlesReports() {
    const endPoint = "/api/OutputBundle/DownloadOutputBundlesReports"
    return api.post(endPoint, {})
  }
  GetInventryWipeStatus() {
    const endPoint: string = `/api/Event/GetInventryWipeStatus`
    return api.findAll(endPoint)
  }
  surveyBanner() {
    const endPoint = "api/Survey/GetSurveyBanner"
    return api.findAll(endPoint)
  }
  surveyQuestions() {
    const endPoint = "api/Survey/GetSurveyQuestions"
    return api.findAll(endPoint)
  }
  updateSurvey(payload) {
    const endPoint = "/api/Survey/AddSurvey"
    return api.post(endPoint, payload)
  }
  postTagValidationData(payload: any, config: any) {
    const endPoint = `api/Range/AddRangeBulk`
    return api.saveWithParams(endPoint, payload, config)
  }
  getCountPageBannersData() {
    const endPoint = `api/Location/GetBannersCount`
    return api.findAll(endPoint)
  }
  getTemplate() {
    const endPoint = "api/Range/GetBulkUploadTemplate"
    return api.findAll(endPoint)
  }
}
