import React, { ReactNode, useState, useEffect } from "react"
import Header from "../components/Header/Header"
import styles from "./MainLayout.module.scss"
import useInventoryStatus from "../hooks/useInventoryStatus"
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined"
import { useDispatch, useSelector } from "react-redux"
import { updateFooterToggleView } from "../redux/slices/countSlice"
import { RootState } from "../redux/store"
import { getInputFiles } from "../redux/slices/configurationSlice"
import { INPUT_FILE_STATUS, INVENTORY_STATUS } from "../constants/common"
import { KEY_CODES } from "src/utils/constants"

interface Props {
  children: ReactNode
}

export default function MainLayout(props: Props) {
  const dispatch = useDispatch()
  const { isDisable } = useInventoryStatus()
  const footerViewFromStore = useSelector(
    (state: RootState) => state.count?.footerToggleView
  )
  const isInputFilesLoaded = useSelector(
    (state: RootState) =>
      state.config?.inputFileData?.status === INPUT_FILE_STATUS.SUCCESS
  )
  const config: any = useSelector((state: RootState) => state.config)
  const { children } = props
  const [toggleFooter, setToggleFooter] = useState(true)
  const footerToggleClick = () => {
    let toggledOption = !toggleFooter
    setToggleFooter(toggledOption)
    dispatch(updateFooterToggleView(toggledOption))
  }
  useEffect(() => {
    setToggleFooter(footerViewFromStore)
  }, [footerViewFromStore])

  useEffect(() => {
    if (
      !isInputFilesLoaded &&
      !["cancelled", "loading"].includes(
        config?.inputFileData?.inputFilesStatus
      )
    ) {
      dispatch(getInputFiles())
    }
  }, [])
  const handleKeyUp = (e: any) => {
    if (e.keyCode === KEY_CODES.ENTER) {
      footerToggleClick()
    }
  }
  return (
    <div
      className={`wrapper d-flex flex-column align-items-stretch ${
        !toggleFooter ? "footer-collapse" : ""
      }`}
    >
      {/* <div  className={pathname === "*"? 'wrapper d-flex flex-column align-items-stretch no-footer': ' wrapper d-flex flex-column align-items-stretch'}> */}
      {/* <SideNav /> */}
      <main className={`flex-grow-1 mw-100 ${styles.MainContentContainer}`}>
        <Header />
        <div
          className={`main-wrapper main-container ${
            isDisable ? "is-inventory" : ""
          }`}
        >
          {children}
        </div>
        <span
          className="footer-btn"
          onClick={footerToggleClick}
          onKeyUp={e => handleKeyUp(e)}
          data-testid="MainKeyBADOIcon"
        >
          <KeyboardArrowDownOutlinedIcon />
        </span>
      </main>

      {/* <Footer /> */}

      {/* </div> */}
    </div>
  )
}
