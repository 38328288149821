import React from "react"
import { Box, CircularProgress } from "@mui/material"

const index = ({ isMarginTop = true, isJustifyContent = true }) => {
  return (
    <Box
      key={"loader"}
      sx={{
        display: "flex",
        alignItems: "center",
        ...(isJustifyContent && { justifyContent: "center" }),
        ...(isMarginTop && { marginTop: "300px" })
      }}
    >
      <CircularProgress />
    </Box>
  )
}

export default index
