import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { CountService } from "../../service/count.service"
import { OORTags, TagCategory, CountSuccess } from "../../utils/types/types"
import { FILTERS_LOCAL_STORE } from "src/utils/constants"
import { sortTagInstances } from "src/utils/helper"
//clearing to avoid retaining old event cache
if (window.location.pathname === "/login") {
  localStorage.removeItem(FILTERS_LOCAL_STORE.COUNT_FILTER)
  localStorage.removeItem(FILTERS_LOCAL_STORE.TAG_RANGE_FILTER)
  localStorage.removeItem(FILTERS_LOCAL_STORE.CONSICE_VIEW)
  localStorage.removeItem(FILTERS_LOCAL_STORE.CONCISE_MAP_DATA)
  localStorage.removeItem(FILTERS_LOCAL_STORE.SHOW_UPDATED_GRID)
  localStorage.removeItem(FILTERS_LOCAL_STORE.SHOW_DELETED)
  localStorage.removeItem(FILTERS_LOCAL_STORE.DUPLICATE_TAG_CONSICE_VIEW)
}
interface FilterInterface {
  status: string
}

export interface CountState {
  status: string
  OORTags: OORTags[]
  oooTagsListStatus: string
  tagCategories: TagCategory[]
  tagCategoriesStatus: string
  CountSuccess: CountSuccess
  countSuccessStatus: string
  OORTagDetails: any
  uploadFileDetails: any
  oooTagDetailsStatus: string
  DuplicateTagsSummary: any
  DuplicateTagsSummaryStatus: string
  DuplicateTagsReview: any
  tagRanges: any
  tagRangesStatus: string
  tagDetails: any
  tagDetailsStatus: string
  countData: any
  countListStatus: string
  rangeData: any
  teamEfficiency: any
  teamEfficiencyStatus: string
  auditProgress: any
  auditProgressStatus: string
  consiceView: boolean
  duplicateTagReviewconsiceView: boolean
  conciseMapData: any
  showDeleted: boolean
  footerToggleView: boolean
  voidedBanner: any
  voidBannerDetailsStatus: string
  duplicateTags: any
  nonDuplicateTags: any
  duplicateTagsReviewStatus: string
  countPageFilter: FilterInterface
  tagRangePageFilter: FilterInterface
  categoriesAccordionList: string[]
  summaryBannerItems: any
  tagDetailsHistory: any
  tagDetailsHistoryStatus: string
  adjustmentFilters: any
  adjustmentFilterForm: any
  isRedirected: boolean
  showUpdatedGrid: boolean
  RefreshTagdetailsGrid: boolean
  inventryWipeStatus: string
  InventoryDataDeleting: boolean
  CountPageBannersData: any
  bannersDataStatus: string
}
const getFilterFromLocalStore = () => {
  // Count Filter
  let COUNT_F: any = localStorage.getItem(FILTERS_LOCAL_STORE.COUNT_FILTER)
  COUNT_F = COUNT_F ? JSON.parse(COUNT_F) : { status: "" }

  // Tag Range Filter
  let TAG_RANGE_F: any = localStorage.getItem(
    FILTERS_LOCAL_STORE.TAG_RANGE_FILTER
  )
  TAG_RANGE_F = TAG_RANGE_F ? JSON.parse(TAG_RANGE_F) : { status: "" }

  // Concise View
  let CONSICE_VIEW: any = localStorage.getItem(FILTERS_LOCAL_STORE.CONSICE_VIEW)
  CONSICE_VIEW = CONSICE_VIEW ? CONSICE_VIEW.toLowerCase() === "true" : false

  // concise map data Tag details
  let CONCISE_MAP_DATA: any = localStorage.getItem(
    FILTERS_LOCAL_STORE.CONCISE_MAP_DATA
  )
  CONCISE_MAP_DATA = CONCISE_MAP_DATA ? JSON.parse(CONCISE_MAP_DATA) : {}

  //SHOW_UPDATED_GRID Tag Details
  let SHOW_UPDATED_GRID: any = localStorage.getItem(
    FILTERS_LOCAL_STORE.SHOW_UPDATED_GRID
  )
  SHOW_UPDATED_GRID = SHOW_UPDATED_GRID
    ? SHOW_UPDATED_GRID.toLowerCase() === "true"
    : false

  // Show Deleted Tag Details
  let SHOW_DELETED: any = localStorage.getItem(FILTERS_LOCAL_STORE.SHOW_DELETED)
  SHOW_DELETED = SHOW_DELETED ? SHOW_DELETED.toLowerCase() === "true" : false

  //DUPLICATE_TAG_CONSICE_VIEW Consice view
  let DUP_CONSICE_VIEW: any = localStorage.getItem(
    FILTERS_LOCAL_STORE.DUPLICATE_TAG_CONSICE_VIEW
  )
  DUP_CONSICE_VIEW = DUP_CONSICE_VIEW
    ? DUP_CONSICE_VIEW.toLowerCase() === "true"
    : false

  return {
    COUNT_F,
    TAG_RANGE_F,
    CONSICE_VIEW,
    CONCISE_MAP_DATA,
    SHOW_UPDATED_GRID,
    SHOW_DELETED,
    DUP_CONSICE_VIEW
  }
}

const filterFromLocalStore = getFilterFromLocalStore()

const initialState: CountState = {
  countData: {},
  countListStatus: "",
  rangeData: {},
  status: "",
  OORTags: [],
  oooTagsListStatus: "",
  tagCategories: [],
  tagCategoriesStatus: "",
  OORTagDetails: {},
  uploadFileDetails: [],
  oooTagDetailsStatus: "",
  DuplicateTagsSummary: {},
  DuplicateTagsSummaryStatus: "",
  DuplicateTagsReview: {},
  tagDetails: {},
  tagDetailsStatus: "",
  tagRanges: {},
  tagRangesStatus: "",
  CountSuccess: {
    locationCountedStatus: false,
    outOfRangeCountedStatus: false,
    deviceLoginStatus: true,
    insertRecordsStatus: true,
    updateRecordsStatus: true,
    systemAuditsStatus: false,
    requiredAuditPercentageStatus: false,
    auditPerCounterStatus: false,
    voidAndCountedStatus: false,
    duplicateLocationStatus: false,
    countResearchVariance: false,
    isChecked: false,
    locationCountedOverridenDetails: [
      {
        overridenBy: "",
        overridenReason: ""
      }
    ],
    outOfRangeCountedOverridenDetails: [
      {
        overridenBy: "",
        overridenReason: ""
      }
    ],
    deviceLoginOverridenDetails: [
      {
        overridenBy: "",
        overridenReason: ""
      }
    ],
    insertRecordsOverridenDetails: [
      {
        overridenBy: "",
        overridenReason: ""
      }
    ],
    updateRecordsOverridenDetails: [
      {
        overridenBy: "",
        overridenReason: ""
      }
    ],
    systemAuditsOverridenDetails: [
      {
        overridenBy: "",
        overridenReason: ""
      }
    ],
    requiredAuditPercentageOverridenDetails: [
      {
        overridenBy: "",
        overridenReason: ""
      }
    ],
    auditPerCounterOverridenDetails: [
      {
        overridenBy: "",
        overridenReason: ""
      }
    ],
    voidAndCountedOverridenDetails: [
      {
        overridenBy: "",
        overridenReason: ""
      }
    ],
    duplicateLocationOverridenDetails: [
      {
        overridenBy: "",
        overridenReason: ""
      }
    ],
    countResearchVarianceOverridenDetails: [
      {
        overridenBy: "",
        overridenReason: ""
      }
    ],
    isCheckedOverridenDetails: [
      {
        overridenBy: "",
        overridenReason: ""
      }
    ]
  },
  countSuccessStatus: "",
  teamEfficiency: {},
  teamEfficiencyStatus: "",
  auditProgress: {},
  auditProgressStatus: "",
  consiceView: filterFromLocalStore.CONSICE_VIEW,
  duplicateTagReviewconsiceView: filterFromLocalStore.DUP_CONSICE_VIEW,
  conciseMapData: filterFromLocalStore.CONCISE_MAP_DATA,
  showDeleted: filterFromLocalStore.SHOW_DELETED,
  footerToggleView: true,
  voidedBanner: [],
  voidBannerDetailsStatus: "",
  duplicateTags: [],
  nonDuplicateTags: [],
  duplicateTagsReviewStatus: "",
  countPageFilter: filterFromLocalStore.COUNT_F,
  tagRangePageFilter: filterFromLocalStore.TAG_RANGE_F,
  categoriesAccordionList: [],
  summaryBannerItems: 0,
  adjustmentFilters: [],
  tagDetailsHistory: [],
  tagDetailsHistoryStatus: "",
  adjustmentFilterForm: {},
  isRedirected: false,
  showUpdatedGrid: filterFromLocalStore.SHOW_UPDATED_GRID,
  RefreshTagdetailsGrid: false,
  inventryWipeStatus: "",
  InventoryDataDeleting: false,
  CountPageBannersData: {},
  bannersDataStatus: ""
}
const initialStateForLogout: CountState = {
  countData: {},
  countListStatus: "",
  rangeData: {},
  status: "",
  OORTags: [],
  oooTagsListStatus: "",
  tagCategories: [],
  tagCategoriesStatus: "",
  OORTagDetails: {},
  uploadFileDetails: [],
  oooTagDetailsStatus: "",
  DuplicateTagsSummary: {},
  DuplicateTagsSummaryStatus: "",
  DuplicateTagsReview: {},
  tagDetails: {},
  tagDetailsStatus: "",
  tagRanges: {},
  tagRangesStatus: "",
  CountSuccess: {
    locationCountedStatus: false,
    outOfRangeCountedStatus: false,
    deviceLoginStatus: true,
    insertRecordsStatus: true,
    updateRecordsStatus: true,
    systemAuditsStatus: false,
    requiredAuditPercentageStatus: false,
    auditPerCounterStatus: false,
    voidAndCountedStatus: false,
    duplicateLocationStatus: false,
    countResearchVariance: false,
    isChecked: false,
    locationCountedOverridenDetails: [
      {
        overridenBy: "",
        overridenReason: ""
      }
    ],
    outOfRangeCountedOverridenDetails: [
      {
        overridenBy: "",
        overridenReason: ""
      }
    ],
    deviceLoginOverridenDetails: [
      {
        overridenBy: "",
        overridenReason: ""
      }
    ],
    insertRecordsOverridenDetails: [
      {
        overridenBy: "",
        overridenReason: ""
      }
    ],
    updateRecordsOverridenDetails: [
      {
        overridenBy: "",
        overridenReason: ""
      }
    ],
    systemAuditsOverridenDetails: [
      {
        overridenBy: "",
        overridenReason: ""
      }
    ],
    requiredAuditPercentageOverridenDetails: [
      {
        overridenBy: "",
        overridenReason: ""
      }
    ],
    auditPerCounterOverridenDetails: [
      {
        overridenBy: "",
        overridenReason: ""
      }
    ],
    voidAndCountedOverridenDetails: [
      {
        overridenBy: "",
        overridenReason: ""
      }
    ],
    duplicateLocationOverridenDetails: [
      {
        overridenBy: "",
        overridenReason: ""
      }
    ],
    countResearchVarianceOverridenDetails: [
      {
        overridenBy: "",
        overridenReason: ""
      }
    ],
    isCheckedOverridenDetails: [
      {
        overridenBy: "",
        overridenReason: ""
      }
    ]
  },
  countSuccessStatus: "",
  teamEfficiency: {},
  teamEfficiencyStatus: "",
  auditProgress: {},
  auditProgressStatus: "",
  consiceView: false,
  duplicateTagReviewconsiceView: false,
  conciseMapData: {},
  showDeleted: false,
  footerToggleView: true,
  voidedBanner: [],
  voidBannerDetailsStatus: "",
  duplicateTags: [],
  nonDuplicateTags: [],
  duplicateTagsReviewStatus: "",
  countPageFilter: { status: "" },
  tagRangePageFilter: { status: "" },
  categoriesAccordionList: [],
  summaryBannerItems: 0,
  adjustmentFilters: [],
  tagDetailsHistory: [],
  tagDetailsHistoryStatus: "",
  adjustmentFilterForm: {},
  isRedirected: false,
  showUpdatedGrid: false,
  RefreshTagdetailsGrid: false,
  inventryWipeStatus: "",
  InventoryDataDeleting: false,
  CountPageBannersData: {},
  bannersDataStatus: ""
}
const countService = new CountService()

export const addTagRange = createAsyncThunk(
  "countList/addTagRange",
  async (payload: any, { rejectWithValue }) => {
    try {
      return await countService.addTagRange(payload)
    } catch (error: any) {
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error)
    }
  }
)

export const getCountList = createAsyncThunk(
  "countList/getCountList",
  async () => {
    return countService.getAllCounts()
  }
)
export const CountPageBannersData = createAsyncThunk(
  "count/CountPageBannersData",
  async () => {
    return countService.getCountPageBannersData()
  }
)
export const getOORTagsList = createAsyncThunk(
  "countList/getOORTagsList",
  async () => {
    return countService.getOORTagsList()
  }
)
export const getTagCategory = createAsyncThunk(
  "countList/getTagCategory",
  async () => {
    return countService.getTagCategory()
  }
)
export const getCountSuccess = createAsyncThunk(
  "countList/getCountSuccess",
  async () => {
    return countService.getCountSuccess()
  }
)

export const getOORTagDetails = createAsyncThunk(
  "countList/getOORTagDetails",
  async () => {
    return countService.getOORTagsDetails()
  }
)

export const getDuplicateTags = createAsyncThunk(
  "countList/getDuplicateTags",
  async () => {
    return countService.getDuplicateTags()
  }
)
export const getDuplicateTagsReview = createAsyncThunk(
  "countList/getDuplicateTagsReview",
  async (params: any) => {
    let locationInsId = params.duplicateInstanceId
    let dupLocationInsId = params.originalInstanceIds
    return countService.getDuplicateTagsReview(locationInsId, dupLocationInsId)
  }
)
export const getVoidBannerDetails = createAsyncThunk(
  "countList/getVoidBannerDetails",
  async () => {
    return countService.getVoidBannerDetails()
  }
)
export const getTagDetails = createAsyncThunk(
  "countList/getTagDetails",
  async (params: any) => {
    let locationValue = params.locationValue
    return countService.getTagDetails(locationValue)
  }
)
export const getTagEditDetails = createAsyncThunk(
  "countList/getTagEditDetails",
  async (params: any) => {
    let locationValue = params.locationValue
    return countService.getTagEditDetails(locationValue)
  }
)
export const getTagDetailsHistory = createAsyncThunk(
  "countList/getTagDetailsHistory",
  async (params: any) => {
    let locationValue = params.locationValue
    return countService.getTagDetailsHistory(locationValue)
  }
)
export const getTagDetailsRecordHistory = createAsyncThunk(
  "countList/getTagDetailsRecordHistory",
  async (params: any) => {
    let locationValue = params.locationValue
    return countService.getTagDetailsRecordHistory(locationValue)
  }
)
export const getTagRanges = createAsyncThunk(
  "countList/getTagRanges",
  async (params: any) => {
    let rangeId = params
    return countService.getTagsRanges(rangeId)
  }
)
export const getConfig = createAsyncThunk("countList/config", async () => {
  return countService.getConfig()
})
export const getTeamEfficiency = createAsyncThunk(
  "countList/getTeamEfficiency",
  async () => {
    return countService.TeamEfficiency()
  }
)
export const getAuditProgress = createAsyncThunk(
  "countList/getAuditProgress",
  async () => {
    return countService.auditProgress()
  }
)

//Count Slice
export const countSlice = createSlice({
  name: "countList",
  initialState,
  reducers: {
    updateConsiceView(state, action: PayloadAction<any>) {
      state.consiceView = action.payload
      localStorage.setItem(
        FILTERS_LOCAL_STORE.CONSICE_VIEW,
        JSON.stringify(state.consiceView)
      )
      return state
    },
    updateDuplicateTagReviewConsiceView(state, action: PayloadAction<any>) {
      state.duplicateTagReviewconsiceView = action.payload
      localStorage.setItem(
        FILTERS_LOCAL_STORE.DUPLICATE_TAG_CONSICE_VIEW,
        JSON.stringify(state.duplicateTagReviewconsiceView)
      )
      return state
    },
    updateShowDeleted(state, action: PayloadAction<any>) {
      state.showDeleted = action.payload
      localStorage.setItem(
        FILTERS_LOCAL_STORE.SHOW_DELETED,
        JSON.stringify(state.showDeleted)
      )
      return state
    },
    updateShowGrid(state, action: PayloadAction<any>) {
      state.showUpdatedGrid = action.payload
      localStorage.setItem(
        FILTERS_LOCAL_STORE.SHOW_UPDATED_GRID,
        JSON.stringify(state.showUpdatedGrid)
      )
      return state
    },
    updateInventeryStatus(state, action: PayloadAction<any>) {
      state.inventryWipeStatus = action.payload
      return state
    },
    InventoryDataDeletingCheck(state, action: PayloadAction<any>) {
      state.InventoryDataDeleting = action.payload
      return state
    },
    clearCountState() {
      return initialStateForLogout
    },
    updateConciseMapData(state, action: PayloadAction<any>) {
      state.conciseMapData = action.payload
      localStorage.setItem(
        FILTERS_LOCAL_STORE.CONCISE_MAP_DATA,
        JSON.stringify(state.conciseMapData)
      )
      return state
    },
    updateFooterToggleView(state, action: PayloadAction<any>) {
      state.footerToggleView = action.payload
      return state
    },
    updateCountPageFilter(state, action: PayloadAction<any>) {
      state.countPageFilter = action.payload
      localStorage.setItem(
        FILTERS_LOCAL_STORE.COUNT_FILTER,
        JSON.stringify(state.countPageFilter)
      )
      return state
    },
    updateTagRangePageFilter(state, action: PayloadAction<any>) {
      state.tagRangePageFilter = action.payload
      localStorage.setItem(
        FILTERS_LOCAL_STORE.TAG_RANGE_FILTER,
        JSON.stringify(state.tagRangePageFilter)
      )
      return state
    },
    updateAccordionList(state, action: PayloadAction<any>) {
      state.categoriesAccordionList = action.payload

      return state
    },

    setSummaryBannerItems(state, action: PayloadAction<any>) {
      state.summaryBannerItems = action.payload
      return state
    },
    setAdjustmentFilters: (state, _action: PayloadAction<any>) => {
      state.adjustmentFilters = [..._action.payload]
    },
    setAdjustmentFilterForm: (state, _action: PayloadAction<any>) => {
      state.adjustmentFilterForm = {
        ..._action.payload
      }
    },
    setIsRedirected: (state, _action: PayloadAction<any>) => {
      state.isRedirected = _action.payload
    }
  },
  extraReducers: builder => {
    builder.addCase(getCountList.pending, state => {
      state.countListStatus = "loading"
    })
    builder.addCase(getCountList.fulfilled, (state, { payload }) => {
      const combined: any = payload?.data?.rangeDetails?.flatMap(obj =>
        obj.ranges.map(range => ({
          ...range,
          category: obj.category,
          label: obj.category + ":" + " " + range.rangeName,
          rightArrow: false
        }))
      )
      state.countListStatus = "success"
      state.countData = (payload && payload.data) || []
      state.rangeData = combined
    })
    builder.addCase(getCountList.rejected, (state, { error }) => {
      if (error.message?.includes("duplicate request")) {
        state.countListStatus = "cancelled"
      } else {
        state.countListStatus = "failed"
      }
    })
    //Out Of range Tags
    builder.addCase(getOORTagsList.pending, state => {
      state.oooTagsListStatus = "loading"
    })
    builder.addCase(getOORTagsList.fulfilled, (state, { payload }) => {
      state.oooTagsListStatus = "success"
      state.OORTags = (payload && payload.data) || []
    })
    builder.addCase(getOORTagsList.rejected, (state, { error }) => {
      if (error.message?.includes("duplicate request")) {
        state.oooTagsListStatus = "cancelled"
      } else {
        state.oooTagsListStatus = "failed"
      }
    })

    //Banners
    builder.addCase(CountPageBannersData.pending, state => {
      state.bannersDataStatus = "loading"
    })
    builder.addCase(CountPageBannersData.fulfilled, (state, { payload }) => {
      state.bannersDataStatus = "success"
      state.CountPageBannersData = (payload && payload.data) || {}
    })
    builder.addCase(CountPageBannersData.rejected, (state, { error }) => {
      if (error.message?.includes("duplicate request")) {
        state.bannersDataStatus = "cancelled"
      } else {
        state.bannersDataStatus = "failed"
      }
    })

    // TagCategory
    builder.addCase(getTagCategory.pending, state => {
      state.tagCategoriesStatus = "loading"
    })
    builder.addCase(getTagCategory.fulfilled, (state, { payload }) => {
      state.tagCategoriesStatus = "success"
      let formatted = []
      if (payload.data) {
        formatted = payload.data.filter(c => c.categoryLabel && c.sortOrder)
      }
      state.tagCategories = formatted || []
    })
    builder.addCase(getTagCategory.rejected, (state, { error }) => {
      if (error.message?.includes("duplicate request")) {
        state.tagCategoriesStatus = "cancelled"
      } else {
        state.tagCategoriesStatus = "failed"
      }
    })

    // CountSuccess
    builder.addCase(getCountSuccess.pending, state => {
      state.countSuccessStatus = "loading"
    })
    builder.addCase(getCountSuccess.fulfilled, (state, { payload }) => {
      state.countSuccessStatus = "success"
      state.CountSuccess = (payload && payload.data) || []
    })
    builder.addCase(getCountSuccess.rejected, (state, { error }) => {
      if (error.message?.includes("duplicate request")) {
        state.countSuccessStatus = "cancelled"
      } else {
        state.countSuccessStatus = "failed"
      }
    })

    //Out Of range Tag details
    builder.addCase(getOORTagDetails.pending, state => {
      state.oooTagDetailsStatus = "loading"
    })
    builder.addCase(getOORTagDetails.fulfilled, (state, { payload }) => {
      state.oooTagDetailsStatus = "success"
      state.OORTagDetails = (payload && payload.data) || {}
    })
    builder.addCase(getOORTagDetails.rejected, (state, { error }) => {
      if (error.message?.includes("duplicate request")) {
        state.oooTagDetailsStatus = "cancelled"
      } else {
        state.oooTagDetailsStatus = "failed"
      }
    })
    //Duplicate Tags
    builder.addCase(getDuplicateTags.pending, state => {
      state.DuplicateTagsSummaryStatus = "loading"
    })
    builder.addCase(getDuplicateTags.fulfilled, (state, { payload }) => {
      state.DuplicateTagsSummaryStatus = "success"
      state.DuplicateTagsSummary = (payload && payload.data) || []
    })
    builder.addCase(getDuplicateTags.rejected, (state, { error }) => {
      if (error.message?.includes("duplicate request")) {
        state.DuplicateTagsSummaryStatus = "cancelled"
      } else {
        state.DuplicateTagsSummaryStatus = "failed"
      }
    })
    //Duplicate Tags Review
    builder.addCase(getDuplicateTagsReview.pending, state => {
      state.duplicateTagsReviewStatus = "loading"
    })
    builder.addCase(getDuplicateTagsReview.fulfilled, (state, { payload }) => {
      state.duplicateTagsReviewStatus = "success"
      state.duplicateTags =
        payload && payload.data && payload.data.duplicateTags
      state.nonDuplicateTags =
        payload && payload.data && payload.data.nonDuplicateTags
    })

    builder.addCase(getDuplicateTagsReview.rejected, (state, { error }) => {
      if (error.message?.includes("duplicate request")) {
        state.duplicateTagsReviewStatus = "cancelled"
      } else {
        state.duplicateTagsReviewStatus = "failed"
      }
    })
    // Tag details
    builder.addCase(getTagDetails.pending, state => {
      state.tagDetailsStatus = "loading"
    })
    builder.addCase(getTagDetails.fulfilled, (state, { payload }) => {
      state.tagDetailsStatus = "success"
      let data = payload && payload.data
      data = { ...state.tagDetails, data }
      data["data"]["locationInstncesData"] = sortTagInstances(
        data?.data?.locationInstncesData || []
      )
      state.tagDetails = data
    })
    builder.addCase(getTagDetails.rejected, (state, { error }) => {
      if (error.message?.includes("duplicate request")) {
        state.tagDetailsStatus = "cancelled"
      } else {
        state.tagDetailsStatus = "failed"
      }
    })
    // Tag Ranges
    builder.addCase(getTagRanges.pending, state => {
      state.tagRangesStatus = "loading"
    })
    builder.addCase(getTagRanges.fulfilled, (state, { payload }) => {
      state.tagRangesStatus = "success"
      state.tagRanges = (payload && payload.data) || {}
    })
    builder.addCase(getTagRanges.rejected, (state, { error }) => {
      if (error.message?.includes("duplicate request")) {
        state.tagRangesStatus = "cancelled"
      } else {
        state.tagRangesStatus = "failed"
      }
    })

    //Team Efficiency
    builder.addCase(getTeamEfficiency.pending, state => {
      state.teamEfficiencyStatus = "loading"
    })
    builder.addCase(getTeamEfficiency.fulfilled, (state, { payload }) => {
      state.teamEfficiencyStatus = "success"
      state.teamEfficiency = (payload && payload.data) || {}
    })
    builder.addCase(getTeamEfficiency.rejected, (state, { error }) => {
      if (error.message?.includes("duplicate request")) {
        state.teamEfficiencyStatus = "cancelled"
      } else {
        state.teamEfficiencyStatus = "failed"
      }
    })
    //Audit Progress
    builder.addCase(getAuditProgress.pending, state => {
      state.auditProgressStatus = "loading"
    })
    builder.addCase(getAuditProgress.fulfilled, (state, { payload }) => {
      state.auditProgressStatus = "success"
      state.auditProgress = (payload && payload.data) || {}
    })
    builder.addCase(getAuditProgress.rejected, (state, { error }) => {
      if (error.message?.includes("duplicate request")) {
        state.auditProgressStatus = "cancelled"
      } else {
        state.auditProgressStatus = "failed"
      }
    })
    //voided banner
    builder.addCase(getVoidBannerDetails.pending, state => {
      state.voidBannerDetailsStatus = "loading"
    })
    builder.addCase(getVoidBannerDetails.fulfilled, (state, { payload }) => {
      state.voidBannerDetailsStatus = "success"
      state.voidedBanner = payload && payload.data
    })
    builder.addCase(getVoidBannerDetails.rejected, (state, { error }) => {
      if (error.message?.includes("duplicate request")) {
        state.voidBannerDetailsStatus = "cancelled"
      } else {
        state.voidBannerDetailsStatus = "failed"
      }
    })
    // Tag details history
    builder.addCase(getTagDetailsHistory.pending, state => {
      state.tagDetailsHistoryStatus = "loading"
    })
    builder.addCase(getTagDetailsHistory.fulfilled, (state, { payload }) => {
      state.tagDetailsHistoryStatus = "success"
      let data = (payload && payload.data) || []
      state.tagDetailsHistory = data
    })
    builder.addCase(getTagDetailsHistory.rejected, (state, { error }) => {
      if (error.message?.includes("duplicate request")) {
        state.tagDetailsHistoryStatus = "cancelled"
      } else {
        state.tagDetailsHistoryStatus = "failed"
      }
    })
  }
})

export const {
  updateConsiceView,
  updateDuplicateTagReviewConsiceView,
  updateShowDeleted,
  clearCountState,
  updateConciseMapData,
  updateFooterToggleView,
  updateCountPageFilter,
  updateAccordionList,
  updateTagRangePageFilter,
  setSummaryBannerItems,
  setAdjustmentFilters,
  setAdjustmentFilterForm,
  setIsRedirected,
  updateShowGrid,
  updateInventeryStatus,
  InventoryDataDeletingCheck
} = countSlice.actions
export default countSlice.reducer
