import React, { useCallback } from "react"
import {
  Button,
  IconButton,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from "@mui/material"
import shortid from "shortid"
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import FilterListIcon from "@mui/icons-material/FilterList"
import { updateTagRangePageFilter } from "../../redux/slices/countSlice"
import {
  updateAuditPageFilter,
  updatePersonsPageFilter
} from "../../redux/slices/auditSlice"
import { useDispatch } from "react-redux"
import useInventoryStatus from "../../../src/hooks/useInventoryStatus"
import { INVENTORY_STATUS } from "src/constants/common"
import { useFilters, useGlobalFilter, useSortBy, useTable } from "react-table"
import { useTranslation } from "react-i18next"
import { Translates } from "src/i18n/i18n"
import InfoIcon from "@mui/icons-material/Info"
import { useHistory } from "react-router-dom"

export function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id }
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options: any = React.useMemo(() => {
    const optionsSet = new Set()
    preFilteredRows.forEach(row => {
      optionsSet.add(row.values[id])
    })
    return [...Array.from(optionsSet.values())]
  }, [id, preFilteredRows])

  // Render a multi-select box
  return (
    <select
      data-testid="GlobTSelectF"
      value={filterValue}
      onChange={e => {
        setFilter(e.target.value || undefined)
      }}
    >
      <option value="">ALL</option>
      {options.map((option, i) => (
        <option key={`${shortid.generate()}`} value={option}>
          {option}
        </option>
      ))}
    </select>
  )
}

const updateFilterToStore = (filterValue, sourcePage, dispatchFunc) => {
  if (sourcePage === "TagRangePage") {
    dispatchFunc(updateTagRangePageFilter({ status: filterValue }))
  }
  if (sourcePage === "personPage") {
    dispatchFunc(updatePersonsPageFilter({ status: filterValue }))
  }
  if (sourcePage === "auditTagView" || sourcePage === "auditPersonView") {
    let filterObj =
      sourcePage === "auditTagView"
        ? { tagViewStatus: filterValue }
        : { personViewStatus: filterValue }

    dispatchFunc(updateAuditPageFilter(filterObj))
  }
}

export function SelectCustomColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id, sourcePage }
}) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  updateFilterToStore(filterValue, sourcePage, dispatch)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = (e: any) => {
    let val = e.target?.attributes?.value?.value
    // Use undefined or null instead of an empty string
    setFilter(val === "" ? undefined : val)
    setAnchorEl(null)
  }
  // Calculate the options for filtering
  // using the preFilteredRows
  let options: any = React.useMemo(() => {
    const optionsCF = new Set()
    preFilteredRows.forEach(row => {
      optionsCF.add(row.values[id])
    })
    return [...Array.from(optionsCF.values())]
  }, [id, preFilteredRows])

  options = options.filter(o => o?.length)
  // Render a multi-select box
  return (
    <>
      <Tooltip arrow title={`${t(Translates.Filter)}`}>
        <IconButton
          data-testid="filterIcon"
          aria-label="more"
          id="long-button"
          aria-controls={open ? "long-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleClick}
          size="small"
          className={
            filterValue && filterValue != "ALL" ? "filter-selected" : ""
          }
        >
          <FilterListIcon />
        </IconButton>
      </Tooltip>
      <Menu
        data-testid="menubox"
        id="long-menu"
        className="filter-menu"
        MenuListProps={{
          "aria-labelledby": "long-button"
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem
          data-testid="globMenuboxItem1"
          value=""
          selected={filterValue === undefined}
          onClick={handleClose}
        >
          {t(Translates.ALL)}
        </MenuItem>
        {options.map(option => (
          <MenuItem
            key={option}
            value={option}
            selected={option === filterValue}
            onClick={handleClose}
            className="upper-case"
          >
            {t(option)}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
const GlobalTable: React.FC<any> = ({
  columns,
  data,
  noDataText = "No Data Found",
  initialStateProp = {
    sortBy: []
  },
  selectedRows,
  onRowSelect,
  onContextMenu,
  OnCellClick,
  voidButton,
  isCountPage,
  handleVoidUnvoid
}) => {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    headerGroups,
    rows,
    prepareRow
    // setGlobalFilter
  } = useTable(
    {
      columns,
      data,
      disableSortRemove: true,
      autoResetSortBy: false,
      autoResetFilters: false,
      initialState: {
        ...initialStateProp
      }
    },
    useFilters, // useFilters!
    useGlobalFilter, // useGlobalFilter!,
    useSortBy
  )
  const { t } = useTranslation()
  const { isDisable } = useInventoryStatus()
  const history = useHistory()
  const checkSorting = (column: any) => {
    if (column.isSorted) {
      return column.isSortedDesc ? (
        <Tooltip arrow title={`${t(Translates.Sort)}`}>
          <ArrowDropDownIcon />
        </Tooltip>
      ) : (
        <Tooltip arrow title={`${t(Translates.Sort)}`}>
          <ArrowDropUpIcon />
        </Tooltip>
      )
    } else {
      return ""
    }
  }
  const rightAlignedColumns = ["ext", "qty", "items", "dollars", "price", "qty"]
  // To show or hide Void Button
  const showVoidBtn = () => {
    if (voidButton) {
      return (
        <>
          <p className="mb-1 font-14 f-600">
            <InfoIcon className="blue-text mr-1" />
            {t(noDataText)}
          </p>
          {"   "}
          <Button
            data-testid="voidUnvoidBtn"
            variant="contained"
            id="primary-btn"
            size="small"
            className="primary-btn px-4"
            disabled={isDisable}
            onClick={handleVoidUnvoid}
          >
            {t(Translates.VOID)}
          </Button>
        </>
      )
    } else {
      return null
    }
  }

  const OnCellClickCallback = useCallback(
    (row, cell) => {
      if (OnCellClick) {
        OnCellClick(row, cell, history)
      }
    },
    [OnCellClick, history]
  )

  const onRowSelectCallback = useCallback(
    (event, row) => {
      if (onRowSelect) {
        onRowSelect(row.original, event)
      }
    },
    [onRowSelect]
  )

  return (
    <Table
      data-testid="mainTable"
      className={`custom-table ${columns.length > 8 ? "table-scroll" : ""}`}
      {...getTableProps()}
    >
      <TableHead>
        {headerGroups.map((headerGroup, hIndex) => (
          <TableRow
            data-testid="mainRows"
            {...headerGroup.getHeaderGroupProps()}
            key={`glob-h-group-${shortid.generate()}`}
            className="test-rowclass"
          >
            {headerGroup.headers.map((column: any) => (
              <TableCell
                style={{ minWidth: `${column.render("setWidth")}px` }}
                key={column.Header}
                className={
                  rightAlignedColumns.includes(column.Header.toLowerCase())
                    ? "text-right"
                    : ""
                }
              >
                <div
                  className="custom-table__sort"
                  {...column.getSortByToggleProps(
                    column.getSortByToggleProps()
                  )}
                  title=" "
                >
                  <span
                    className={`custom-table__sort-icon ${
                      column.canFilter ? "with-filter" : ""
                    }`}
                  >
                    {checkSorting(column)}
                  </span>
                  <span title={column.Header}>{column.render("Header")}</span>
                </div>
                <span className="custom-table__filter">
                  {column.canFilter ? column.render("Filter") : null}
                </span>
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableHead>
      <TableBody>
        {(!rows || rows.length == 0) && (
          <TableRow>
            <TableCell colSpan={columns.length}>
              <Typography align="center" variant="subtitle1">
                {isCountPage ? showVoidBtn() : <>{t(noDataText)}</>}
              </Typography>
            </TableCell>
          </TableRow>
        )}

        {rows.map((row: any, _i) => {
          prepareRow(row)
          const onClickHandler = event => onRowSelectCallback(event, row)
          return (
            <TableRow
              data-testid="GlobRenderRows"
              {...row.getRowProps()}
              onClick={onClickHandler}
              onContextMenu={event => onContextMenu(event, row.original)}
              className={`${
                selectedRows?.length &&
                selectedRows.some(
                  selectedRow =>
                    selectedRow.idInventoryData === row.original.idInventoryData
                )
                  ? "selected-row"
                  : ""
              } ${row.original.isDeleted ? "strike-through" : ""}`}
            >
              {row.cells.map(cell => {
                const onClickHandler1 = () => OnCellClickCallback(row, cell)
                return (
                  <TableCell
                    data-testid="GlobRenderCells"
                    {...cell.getCellProps()}
                    onClick={onClickHandler1}
                  >
                    {cell.render("Cell")}
                  </TableCell>
                )
              })}
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  )
}

export default GlobalTable
