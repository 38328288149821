import React from "react"
import { Grid } from "@mui/material"
import TagIcon from "../../../src/assets/images/TagIcon.svg"
import ProgressBarStatus from "../Count/ProgressBarStatus"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import useLocationLable from "../../../src/hooks/useLocationLable"
import { useTranslation } from "react-i18next"
import { renderAccuracySmiley } from "../Team/teamHelper"
import { Translates } from "src/i18n/i18n"
import { formatWithCurrencyLang } from "src/utils/helper"
interface AuditSummary {
  auditSummary: any
}

const AuditSummaryBanner: React.FC<AuditSummary> = ({ auditSummary }) => {
  const auditsRequiredPerCounter = () => {
    if (auditSummary.requiredAuditsPerCounter === 1) {
      return t(Translates.audit_per_counter)
    } else {
      return t(Translates.audits_per_counter)
    }
  }
  const { locationLable, checkPlural } = useLocationLable()
  const { t } = useTranslation()
  return (
    <Grid container className="tag-list blue-bg mb-2">
      <Grid item md={9} lg={10} className="tag-list__cont">
        <img src={TagIcon} alt="tag icon" className="tag-list__icon" />
        {auditSummary?.isAuditTypeRule && (
          <div className="tag-list__col">
            <span className="tag-list__col-title">
              {" "}
              {t(Translates.System_Audits)}:{""}
            </span>
            <strong className="tag-list__col-value">
              {`${formatWithCurrencyLang(
                `${auditSummary.xofSystemAudits}`
              )} ${t(Translates.OF)} ${formatWithCurrencyLang(
                `${auditSummary.yofSystemAudits}`
              )} ${t(Translates.COMPLETED)}`}
            </strong>
          </div>
        )}
        {auditSummary?.requiredAuditsPerCounter ? (
          <div className="tag-list__col">
            <span className="tag-list__col-title">
              {auditSummary.requiredAuditsPerCounter}{" "}
              {`${auditsRequiredPerCounter()}`}:{""}
            </span>
            <strong className="tag-list__col-value">
              {`${formatWithCurrencyLang(
                `${auditSummary.xofAuditsPerCounter}`
              )} ${t(Translates.OF)} ${formatWithCurrencyLang(
                `${auditSummary.yofNumberOfCounters}`
              )} ${t(Translates.COUNTERS)} ${t(Translates.COMPLETED)}`}
            </strong>
          </div>
        ) : null}
        {auditSummary?.requiredAuditPercentage ? (
          <div className="tag-list__col">
            <span className="tag-list__col-title">
              {t(Translates.Audit)} {auditSummary.requiredAuditPercentage}%{" "}
              {t(locationLable)}
              {"s"}:
            </span>
            <strong className="tag-list__col-value">
              {`${auditSummary.xofAuditedTags}% `}
              {auditSummary.xofAuditedTags >=
                auditSummary.requiredAuditPercentage && (
                <CheckCircleIcon
                  style={{ display: "inline-flex" }}
                  className="accuracy-status success"
                ></CheckCircleIcon>
              )}
            </strong>
          </div>
        ) : null}
        <div className="tag-list__col">
          <span className="tag-list__col-title">
            {t(Translates.accuracy)}:{""}
          </span>
          <strong className="tag-list__col-value">
            {renderAccuracySmiley(
              auditSummary?.overallAccuracy,
              auditSummary?.goal,
              auditSummary?.secondaryGoal
            )}
          </strong>
        </div>
      </Grid>
      <Grid item md={3} lg={2} className="tag-list__cont text-right">
        <div className="custom-progress ma-0">
          <ProgressBarStatus
            progress={auditSummary?.auditProgress || "0"}
            showWhiteBackground={true}
            isAudit={true}
            isTeam={false}
            isVariance={false}
            isVarianceSummary={false}
          ></ProgressBarStatus>
        </div>
        <span className="d-block text-uppercase">
          {`${formatWithCurrencyLang(auditSummary?.auditedTags)} ${t(
            Translates.of
          )} ${formatWithCurrencyLang(
            `${auditSummary?.yofOverAllAuditProgress}`
          )} ${checkPlural(auditSummary?.auditedTags)} ${t(
            Translates.Audited
          )}`}
        </span>
      </Grid>
    </Grid>
  )
}
export default AuditSummaryBanner
