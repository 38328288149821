import React, { useEffect, useState } from "react"
import AuditToolBarHeader from "../../../src/components/AuditToolbar/AuditToolBarHeader"
import MainLayout from "../../../src/layouts/MainLayout"
import AuditNoDataBanner from "./AuditNoDataBanner"
import AuditPersonView from "./AuditPersonView"
import AuditSummaryBanner from "./AuditSummaryBanner"
import AuditTagView from "./AuditTagView"
import { useSelector } from "react-redux"
import { RootState, useAppThunkDispatch } from "../../redux/store"
import {
  AuditPagePoll,
  clearPoll,
  intervalVarNames
} from "../../../src/service/poll.service"
import { AuditService } from "../../../src/service/audit.service"
import { updateAuditPageFilter } from "../../redux/slices/auditSlice"
import InventoryStatusBanner from "../../../src/pages/CloseInventory/InventoryStatusBanner"
import useInventoryStatus from "../../../src/hooks/useInventoryStatus"
import styles from "../../../src/components/AuditToolbar/AuditToolBarHeader.module.scss"
import Loader from "src/components/Loader"

const Audit: React.FC<any> = () => {
  const { isDisable } = useInventoryStatus()
  const [isPersonView, setIsPersonView] = useState(false)
  const dispatch = useAppThunkDispatch()
  const auditSummaryFromState = useSelector(
    (state: RootState) => state.audit.auditSummary
  )
  const [auditSummary, setAuditSummary] = useState<any>({})
  const [auditTags, setAuditTags] = useState<any>([])
  const auditService = new AuditService()
  const pageViewFromStore = useSelector(
    (state: RootState) => state.audit.auditPageFilter.view
  )
  useEffect(() => {
    setIsPersonView(pageViewFromStore === "personView")
  }, [pageViewFromStore])
  const handleToggle = val => {
    dispatch(updateAuditPageFilter({ view: val ? "personView" : "tagView" }))
    setIsPersonView(val)
  }
  const isNoData = () => {
    return !auditTags.length ? true : false
  }
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setAuditSummary(auditSummaryFromState)
  }, [auditSummaryFromState])

  useEffect(() => {
    setLoading(true)
    auditService
      .getAuditSummary()
      .then(res => {
        setAuditSummary(res.data || {})
        showAuditTags()
      })
      .catch(err => {
        if (typeof err === "string" && !err.includes("duplicate request")) {
          setLoading(false)
        }
      })
    return () => {
      clearPoll([intervalVarNames.AUDITPAGE], "auditPage")
    }
  }, [])
  const showAuditTags = () => {
    auditService
      .getAuditTags()
      .then(res => {
        setAuditTags(res.data || [])
        setLoading(false)
        AuditPagePoll(dispatch)
      })
      .catch(err => {
        if (typeof err === "string" && !err.includes("duplicate request")) {
          setLoading(false)
        }
      })
  }
  return (
    <MainLayout>
      {loading ? <Loader /> : null}
      <>
        {isDisable ? (
          <div className={`${styles.inventoryBanner}`}>
            <div className={`main-container inventory-banner`}>
              <InventoryStatusBanner></InventoryStatusBanner>
            </div>
          </div>
        ) : null}
        <AuditToolBarHeader
          handleToggle={val => handleToggle(val)}
          showToggle={!isNoData()}
        />
        <div className="fit-content">
          {!isNoData() && !loading && Object.keys(auditSummary).length > 0 && (
            <AuditSummaryBanner auditSummary={auditSummary} />
          )}
          {!isNoData() && !loading && !isPersonView && (
            <AuditTagView auditSummary={auditSummary} auditTags={auditTags} />
          )}
          {!isNoData() && !loading && isPersonView && (
            <AuditPersonView auditSummary={auditSummary} />
          )}
        </div>
        {isNoData() && !loading && <AuditNoDataBanner />}
      </>
    </MainLayout>
  )
}

export default Audit
