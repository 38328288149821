import React, { useEffect, useState } from "react"
import { t } from "i18next"
import { useSelector } from "react-redux"
import { Translates } from "src/i18n/i18n"
import { AUDIT_VIEW } from "src/utils/constants"
import { RootState } from "../../redux/store"
import BreadCrumb from "../BreadCrumb/BreadCrumb"
import CustomToggleButton from "../CustomToggleButton/CustomToggleButton"
import styles from "./AuditToolBarHeader.module.scss"

const AuditToolBarHeader = (props: any) => {
  const pageViewFromStore = useSelector(
    (state: RootState) => state.audit.auditPageFilter.view
  )
  const [defaultPageView, setDefaultPageView] = useState(AUDIT_VIEW.TAG_VIEW)
  useEffect(() => {
    setDefaultPageView(pageViewFromStore)
  }, [pageViewFromStore])
  const getBreadcrumbConfig = () => {
    let currentPath: string = `audit`
    let variables: any = {
      audit: !props.showToggle ? t(Translates.Audits) : t(Translates.Audit)
    }
    let bConfig: any = {
      currentPath,
      mapObj: variables
    }
    return bConfig
  }
  return (
    <div id={`toolbar-header-container`} className={`${styles.Container}`}>
      <div className={`main-container ${styles.ContainerInner}`}>
        <div className="custom-breadcrumb">
          <BreadCrumb
            customPath={"Audit"}
            breadCrumbInfo={getBreadcrumbConfig()}
            renderedPage={""}
            curTagNumber={""}
            curRangeId={""}
            isEditTagDisabled={false}
          />
        </div>
        {props.showToggle ? (
          <div>
            <CustomToggleButton
              handleToggle={val => props.handleToggle(val)}
              defaultValue={defaultPageView}
              tab={AUDIT_VIEW.AUDIT_TYPE}
            />
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default AuditToolBarHeader
