import React from "react"
import { Alert, AlertTitle } from "@mui/material"
import WarningIcon from "@mui/icons-material/Warning"
import { useTranslation } from "react-i18next"
import { Translates } from "src/i18n/i18n"
import useInventoryStatus from "src/hooks/useInventoryStatus"
const InventoryStatusBanner = () => {
  const { t } = useTranslation()
  const { isLocked, isCompleted } = useInventoryStatus()
  return (
    <>
      <Alert severity="error" icon={<WarningIcon />} className="custom-alert">
        <AlertTitle className="custom-alert__title">
          {isLocked
            ? t(Translates.Inventory_Locked)
            : t(Translates.Inventory_Closed)}
        </AlertTitle>
        <p>
          {isLocked
            ? t(
                Translates.The_Inventory_is_locked_No_changes_can_be_made_at_this_time
              )
            : t(
                Translates.The_Inventory_is_closed_No_changes_can_be_made_at_this_time
              )}
        </p>
      </Alert>
    </>
  )
}

export default InventoryStatusBanner
