import React from "react"
import { useTranslation } from "react-i18next"
import { COUNT_STATUS, VARIANCES_STATUS } from "src/utils/constants"
import { COLOR } from "../../constants/styles"
type PBSprops = {
  progress: any
  showWhiteBackground: boolean
  isAudit: boolean
  isTeam: boolean
  isVariance: boolean
  isVarianceSummary: boolean
}

const ProgressBarStatus: React.FC<PBSprops> = ({
  progress,
  showWhiteBackground = true,
  isAudit,
  isTeam,
  isVariance,
  isVarianceSummary
}) => {
  const { t } = useTranslation()

  const status = COUNT_STATUS.NOTSTARTED
  const varianceStatus = VARIANCES_STATUS.NOTRESEARCHED
  let roundedPercentage = progress < 99 ? Math.round(progress) : Math.floor(progress);

  const getProgressColor = () => {
    if (isAudit) {
      if (progress < 100) {
        if (progress === 0) {
          return "gray-progressbar"
        } else {
          return "audit-progressbar light"
        }
      } else {
        return "audit-progressbar"
      }
    } else if (isVariance) {
      if (progress < 100) {
        if (progress === 0) {
          return "gray-progressbar"
        } else {
          return "variance-progressbar light"
        }
      } else {
        return "variance-progressbar"
      }
    } else {
      if (progress < 100) {
        if (progress === 0) {
          return "gray-progressbar"
        } else {
          return "count-progressbar light"
        }
      } else {
        return "count-progressbar"
      }
    }
  }
  const displayProgressText = () => {
    if (progress !== 0) {
      return <strong>{roundedPercentage + "%"}</strong>
    } else if (isVariance && !isVarianceSummary) {
      return <span className="text-uppercase">{t(varianceStatus)}</span>
    } else {
      return <span className="text-uppercase">{t(status)}</span>
    }
  }
  const displayWidth = () => {
    if (roundedPercentage < 11) {
      return "30px"
    } else if (roundedPercentage > 11 && roundedPercentage < 25) {
      return "40px"
    }
    return `${roundedPercentage}%`
  }
  return (
    <div
      style={{
        width: "7rem",
        height: "1.3rem",
        backgroundColor: showWhiteBackground
          ? COLOR.white
          : COLOR.notStartedProgress
      }}
    >
      <span className={getProgressColor()} style={{ width: displayWidth() }}>
        {displayProgressText()}
      </span>
    </div>
  )
}

export default ProgressBarStatus
