import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import styles from "./ToolBar.module.scss"
import IconButton from "@mui/material/IconButton"
import FilledInput from "@mui/material/FilledInput"
import InputAdornment from "@mui/material/InputAdornment"
import FormControl from "@mui/material/FormControl"
import SearchIcon from "@mui/icons-material/Search"
import Button from "@mui/material/Button"
import AddIcon from "@mui/icons-material/Add"
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined"
import ApiService from "../../../src/utils/useAxios"
import { useHistory } from "react-router-dom"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import useInventoryStatus from "../../../src/hooks/useInventoryStatus"
import useLocationLable from "../../../src/hooks/useLocationLable"
import { useTranslation } from "react-i18next"
import { updateCountPageFilter } from "../../../src/redux/slices/countSlice"
import { RootState } from "src/redux/store"
import { Tooltip } from "@mui/material"
import {
  checkIsAlphaFromConfig,
  encryptUrlParams
} from "../../../src/utils/helper"
import { Translates } from "src/i18n/i18n"
import { INVENTORY_STATUS } from "src/constants/common"
import { getConfig } from "src/redux/slices/configurationSlice"

const api = new ApiService()

type NewRange = {
  callback?: any
  handleFilter: any
}
const options = [
  Translates.ALL,
  Translates.NOT_STARTED,
  Translates.IN_PROGRESS,
  Translates.COMPLETED
]

const ToolBar: React.FC<NewRange> = ({ handleFilter, callback = {} }) => {
  const filterFromStore = useSelector(
    (state: RootState) => state.count.countPageFilter.status
  )
  const { t } = useTranslation()
  const [searchTagError, setSearchTagErr] = useState(false)
  const [searchTagErrorMsg, setSearchTagErrMsg] = useState("Tag does not exist")
  const [selectedIndex, setSelectedIndex] = React.useState(0)
  const [fetchingTag, setFetchingTag] = useState(false)
  const [searchTag, setSearchTag] = useState("")
  const history = useHistory()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const { isDisable } = useInventoryStatus()
  const { locationLable } = useLocationLable()
  const open = Boolean(anchorEl)
  const [selectedFilter, setSelectedFilter] = useState<string | null>(null)
  const config: any = useSelector((state: RootState) => state.config)
  const configData: any = config?.configData
  const dispatch = useDispatch()
  useEffect(() => {
    if (
      !Object.keys(configData || {}).length &&
      !["cancelled", "loading"].includes(config?.status)
    ) {
      dispatch(getConfig())
    }
  }, [])
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleMenuItemClick = (event, index, value) => {
    dispatch(updateCountPageFilter({ status: value }))
    setSelectedIndex(index)
    setAnchorEl(null)
    setSelectedFilter(event.currentTarget.innerText)
    handleFilter(event.currentTarget.innerText)
  }
  useEffect(() => {
    setSelectedFilter(filterFromStore)
    let index: number = options.findIndex(option => option === filterFromStore)
    setSelectedIndex(index)
  }, [filterFromStore])

  const handleClose = () => {
    setAnchorEl(null)
  }

  const fetchTagDetails = () => {
    setFetchingTag(true)
    setSearchTagErr(false)
    api
      .findAll(`/api/Location/GetTagDetails/${searchTag}`)
      .then((res: any) => {
        setFetchingTag(false)
        if (res && res.data) {
          let rangeId: string | null = res.data.rangeId || null
          if (!rangeId) {
            setSearchTagErrMsg(`${t(Translates.can_not_fetch_range_Id)}`)
            setSearchTagErr(true)
          } else {
            let redirectRoute = `/count/${encryptUrlParams(
              rangeId
            )}/${encryptUrlParams(searchTag)}`
            history.push(redirectRoute)
          }
        }
      })
      .catch((err: any) => {
        setSearchTagErrMsg(
          `${t(locationLable)} ${t(Translates.does_not_exist)}`
        )
        setSearchTagErr(true)
        setFetchingTag(false)
        console.error(err)
      })
  }
  const filterButtonClass = () => {
    let classStr: string = `ml-2 px-2 secondary-btn ${styles.FilterButton}`
    if (selectedFilter && selectedFilter !== "ALL") {
      return `${classStr} filter-selected`
    } else {
      return classStr
    }
  }

  const handleGoToTag = (event: any) => {
    let val: string = event.target.value
    setSearchTag(val)
    setSearchTagErr(false)
    // debounceSearchTag(val)
  }
  const onSearchTagKey = (event: any, type: string) => {
    if (
      ((type == "keyup" && event.keyCode && event.keyCode == 13) ||
        type == "btnclick") &&
      searchTag &&
      searchTag.length
    ) {
      /// Enter key
      fetchTagDetails()
    }
  }

  return (
    <div className="d-flex align-items-center">
      <FormControl variant="filled">
        <FilledInput
          id="filled-adornment-password"
          type={checkIsAlphaFromConfig(configData) === false ? "number" : ""}
          data-testid="tool-search"
          placeholder={`${t(Translates.Go_to)} ${t(locationLable)}`}
          classes={{
            root: searchTagError
              ? `${styles.SeacrchField} ${styles.SeacrchFieldErr}`
              : `${styles.SeacrchField}`,
            input: `${styles.InputBox}`
          }}
          inputProps={{ maxLength: 60 }}
          error={searchTagError}
          value={searchTag}
          autoComplete={"off"}
          onChange={e => handleGoToTag(e)}
          onKeyUp={e => onSearchTagKey(e, "keyup")}
          startAdornment={
            <InputAdornment
              classes={{
                root: `${styles.InputIcon}`
              }}
              position="start"
            >
              <IconButton
                data-testid="onSearchTagKeybtnclick"
                aria-label="toggle password visibility"
                edge="start"
                onClick={e => onSearchTagKey(e, "btnclick")}
              >
                <SearchIcon></SearchIcon>
              </IconButton>
            </InputAdornment>
          }
        />
        {searchTagError && (
          <span className="validate-msg">{searchTagErrorMsg}</span>
        )}
        {fetchingTag && (
          <span className="validate-msg black-text">
            {t(Translates.Fetching)}...
          </span>
        )}
      </FormControl>

      <Tooltip arrow title={t(Translates.Filter)}>
        <Button
          id="basic-button"
          variant="text"
          className={filterButtonClass()}
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          <FilterListOutlinedIcon />
        </Button>
      </Tooltip>
      <Menu
        data-testid="toolbar-menu"
        id="basic-menu"
        className="filter-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        MenuListProps={{
          "aria-labelledby": "basic-button"
        }}
      >
        {options.map((option, index) => (
          <MenuItem
            data-testid={`menuitem${index}`}
            key={option}
            onClick={event => handleMenuItemClick(event, index, option)}
            selected={index === selectedIndex}
            value={option}
            style={{ textTransform: "uppercase" }}
          >
            {t(option)}
          </MenuItem>
        ))}
      </Menu>
      <Button
        className={`ml-2 primary-btn ${styles.PrimaryButton}`}
        variant="contained"
        startIcon={<AddIcon />}
        disabled={isDisable}
        onClick={() => callback(true)}
        data-testid="newRange"
      >
        {t(Translates.New_Range)}
      </Button>
    </div>
  )
}

export default ToolBar
