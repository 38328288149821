import ApiService from "../utils/useAxios"

const api = new ApiService()
export class InputFilesService {
  getInputFiles() {
    const endpoint = "api/InputFiles"
    return api.findAll(endpoint)
  }

  getMissingFiles() {
    const endpoint = "api/Variance/GetFileMissingBanner"
    return api.findAll(endpoint)
  }
}