import * as React from "react"
import ToggleButton from "@mui/material/ToggleButton"
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup"
import useLocationLable from "../../../src/hooks/useLocationLable"
import { useTranslation } from "react-i18next"
import { AUDIT_VIEW, VARIANCE_TYPE } from "src/utils/constants"
import { Translates } from "src/i18n/i18n"
import { useSelector } from "react-redux"
import { RootState } from "../../redux/store"
type toggle = {
  handleToggle: any
  defaultValue: string
  tab: string
}

const CustomToggleButton: React.FC<toggle> = ({
  handleToggle,
  defaultValue,
  tab
}) => {
  const config = useSelector((state: RootState) => state.config?.configData)
  const [alignment, setAlignment] = React.useState(
    defaultValue || AUDIT_VIEW.TAG_VIEW
  )
  const [alignment1, setAlignment1] = React.useState(
    defaultValue || VARIANCE_TYPE.FULLVARIANCE
  )
  const { locationLable } = useLocationLable()

  const { t } = useTranslation()
  const handleChange = (_event, newAlignment) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment)
      handleToggle(newAlignment === "personView")
    }
  }
  const handleChange1 = (_event, newAlignment) => {
    if (newAlignment !== null) {
      setAlignment1(newAlignment)
      handleToggle(newAlignment === VARIANCE_TYPE.GROUPVARIANCE)
    }
  }
  const getFullVBtnLabel = () => {
    return config?.FullStoreVariances &&
      config.FullStoreVariances[0] &&
      config.FullStoreVariances[0].Name
      ? config.FullStoreVariances[0].Name
      : t(Translates.Full_Variance)
  }
  const getGroupedVBtnLabel = () => {
    return config?.GroupedVariances &&
      config.GroupedVariances[0] &&
      config.GroupedVariances[0].Name
      ? config.GroupedVariances[0].Name
      : t(Translates.Group_Variance)
  }
  if (tab === AUDIT_VIEW.AUDIT_TYPE) {
    return locationLable ? (
      <ToggleButtonGroup
        color="primary"
        className="toggle-buttons"
        value={alignment}
        exclusive
        onChange={handleChange}
      >
        <ToggleButton
          role={"button"}
          value={AUDIT_VIEW.TAG_VIEW}
          data-testid="ToggTagView"
        >
          {t(locationLable)} {t(Translates.View)}
        </ToggleButton>
        <ToggleButton
          role={"button"}
          value={AUDIT_VIEW.PERSON_VIEW}
          data-testid="ToggPersonView"
        >
          {t(Translates.Person_View)}
        </ToggleButton>
      </ToggleButtonGroup>
    ) : null
  } else {
    return (
      <ToggleButtonGroup
        color="primary"
        className="toggle-buttons"
        value={alignment1}
        exclusive
        onChange={handleChange1}
      >
        <ToggleButton
          role={"button"}
          value={VARIANCE_TYPE.FULLVARIANCE}
          data-testid="full-toggle-btn"
        >
          {getFullVBtnLabel()}
        </ToggleButton>
        <ToggleButton
          role={"button"}
          value={VARIANCE_TYPE.GROUPVARIANCE}
          data-testid="group-toggle-btn"
        >
          {getGroupedVBtnLabel()}
        </ToggleButton>
      </ToggleButtonGroup>
    )
  }
}

export default CustomToggleButton
