import React, { lazy, Suspense } from "react"
import { Redirect, Switch, Route } from "react-router-dom"
import AuthenticatedGuard from "../guards/AuthenticatedGuard"
import { PATH } from "../constants/paths"
import Loader from "../components/Loader"
import Footer from "../components/Footer/Footer"
import Audit from "../pages/Audit/Audit"
import Team from "../pages/Team/Team"
import VarianceUploadDetails from "src/pages/Variance/VarianceUploadDetails"
import ResetTheInventoryData from "src/pages/ResetTheInventoryData"
import TagUploadContainer from "src/pages/Count/TagUploadContainer"
const CustomerSatisfactionSurvey = lazy(
  () => import("src/pages/CloseInventory/CustomerSatisfactionSurvey")
)
const TagRangePage = lazy(() => import("../pages/Count/TagRangePage"))
const Count = lazy(() => import("../pages/Count/Count"))
const TagDetails = lazy(() => import("../pages/TagDetails/TagDetails"))
const OutOfRangeTags = lazy(() => import("../pages/Count/OutOfRangeTags"))
const DuplicateTagsSummary = lazy(
  () => import("../pages/Count/DuplicateTagsSummary")
)
const DuplicateTagsReview = lazy(
  () => import("../pages/Count/DuplicateTagsReview")
)
const PageNotFound = lazy(() => import("../pages/PageNotFound/PageNotFound"))
const Reports = lazy(() => import("../pages/Report/Report"))
const ReportDetails = lazy(() => import("../pages/Report/ReportDetails"))
const CloseInventory = lazy(
  () => import("../pages/CloseInventory/CloseInventory")
)
const Login = lazy(() => import("../pages/Login/Login"))
const PersonsView = lazy(() => import("../pages/Audit/PersonsView"))
const TeamPersonsView = lazy(() => import("../pages/Team/TeamPersonsView"))
const VoidedAndCounted = lazy(() => import("../pages/Count/VoidedAndCounted"))
const Variance = lazy(() => import("../pages/Variance/Variance"))
const VarianceUploadFile = lazy(
  () => import("../pages/Variance/VarianceUploadFile")
)
const CreateVariance = lazy(() => import("../pages/Variance/CreateVariance"))
const ResearchVariance = lazy(
  () => import("../pages/Variance/ResearchVariance")
)
const TagDetailsHistory = lazy(
  () => import("../pages/TagDetails/TagDetailsHistory")
)
const AdjustmentSummaryReport = lazy(
  () =>
    import("../pages/Report/AdjustmentSummaryReport/AdjustmentSummaryReport")
)

const AVOID_FOOTER = [
  PATH.LOGIN,
  PATH.PAGE_NOT_FOUND,
  PATH.CUSTOMER_SATISFACTION_SURVEY,
  PATH.CREATE_TAGS_BULK
]
const ACC_TOKEN = "accessToken"
const shouldFooterRender = () => {
  return (
    localStorage.getItem(ACC_TOKEN) &&
    !AVOID_FOOTER.includes(window.location.pathname)
  )
}

export default function RouteList(_props) {
  return (
    <>
      <Switch>
        <Route
          path={PATH.LOGIN}
          component={() => (
            <Suspense fallback={<Loader />}>
              <Login />
            </Suspense>
          )}
        />
        <Redirect exact from={PATH.HOME} to={PATH.COUNT} />
        <AuthenticatedGuard
          exact
          path={PATH.COUNT}
          component={() => (
            <Suspense fallback={<Loader />}>
              <Count />
            </Suspense>
          )}
        />
        <AuthenticatedGuard
          exact
          path={PATH.AUDIT}
          component={() => (
            <Suspense fallback={<Loader />}>
              <Audit />
            </Suspense>
          )}
        />
        <AuthenticatedGuard
          exact
          path={PATH.TEAM}
          component={() => (
            <Suspense fallback={<Loader />}>
              <Team />
            </Suspense>
          )}
        />
        <AuthenticatedGuard
          exact
          path={PATH.VOIDED_AND_COUNTED}
          component={() => (
            <Suspense fallback={<Loader />}>
              <VoidedAndCounted />
            </Suspense>
          )}
        />
        <AuthenticatedGuard
          exact
          path={PATH.OUT_OF_RANGE_TAGS}
          component={() => (
            <Suspense fallback={<Loader />}>
              <OutOfRangeTags />
            </Suspense>
          )}
        />
        <AuthenticatedGuard
          exact
          path={PATH.DUPLICATE_TAGS}
          component={() => (
            <Suspense fallback={<Loader />}>
              <DuplicateTagsSummary />
            </Suspense>
          )}
        />
        <AuthenticatedGuard
          exact
          path={PATH.DUPLICATE_TAGS_REVIEW}
          component={() => (
            <Suspense fallback={<Loader />}>
              <DuplicateTagsReview />
            </Suspense>
          )}
        />

        <AuthenticatedGuard
          exact
          path={PATH.TAG_RANGE_PAGE}
          component={props => (
            <Suspense fallback={<Loader />}>
              <TagRangePage {...props} />
            </Suspense>
          )}
        />
        <AuthenticatedGuard
          exact
          path={PATH.TAG_DETAILS}
          component={() => (
            <Suspense fallback={<Loader />}>
              <TagDetails />
            </Suspense>
          )}
        />
        <AuthenticatedGuard
          exact
          path={PATH.RESEARCH_VARIANCE_TAG_DETAILS}
          component={() => (
            <Suspense fallback={<Loader />}>
              <TagDetails />
            </Suspense>
          )}
        />
        <AuthenticatedGuard
          exact
          path={PATH.AUDIT_TAG_DETAILS}
          component={() => (
            <Suspense fallback={<Loader />}>
              <TagDetails />
            </Suspense>
          )}
        />
        <AuthenticatedGuard
          exact
          path={PATH.PERSON_TAG_DETAILS}
          component={() => (
            <Suspense fallback={<Loader />}>
              <TagDetails />
            </Suspense>
          )}
        />
        <AuthenticatedGuard
          exact
          path={PATH.TEAM_TAG_DETAILS}
          component={() => (
            <Suspense fallback={<Loader />}>
              <TagDetails />
            </Suspense>
          )}
        />
        <AuthenticatedGuard
          exact
          path={PATH.REPORTS}
          component={() => (
            <Suspense fallback={<Loader />}>{<Reports />}</Suspense>
          )}
        />
        <AuthenticatedGuard
          exact
          path={PATH.REPORTS_DETAILS}
          component={() => (
            <Suspense fallback={<Loader />}>{<ReportDetails />}</Suspense>
          )}
        />

        <AuthenticatedGuard
          exact
          path={PATH.CLOSE_INVENTORY}
          component={() => (
            <Suspense fallback={<Loader />}>
              <CloseInventory />
            </Suspense>
          )}
        />
        <AuthenticatedGuard
          exact
          path={PATH.PERSONS_VIEW}
          component={() => (
            <Suspense fallback={<Loader />}>{<PersonsView />}</Suspense>
          )}
        />
        <AuthenticatedGuard
          exact
          path={PATH.TEAM_PERSONS_VIEW}
          component={() => (
            <Suspense fallback={<Loader />}>{<TeamPersonsView />}</Suspense>
          )}
        />
        <AuthenticatedGuard
          exact
          path={PATH.REPORT_DRILL_DOWN_DETAILS}
          component={() => (
            <Suspense fallback={<Loader />}>{<TagDetails />}</Suspense>
          )}
        />
        <AuthenticatedGuard
          exact
          path={PATH.VARIANCE}
          component={() => (
            <Suspense fallback={<Loader />}>{<Variance />}</Suspense>
          )}
        />
        <AuthenticatedGuard
          exact
          path={PATH.VARIANCE_UPLOAD_FILE}
          component={() => (
            <Suspense fallback={<Loader />}>{<VarianceUploadFile />}</Suspense>
          )}
        />
        <AuthenticatedGuard
          exact
          path={PATH.CREATE_VARIANCE}
          component={() => (
            <Suspense fallback={<Loader />}>{<CreateVariance />}</Suspense>
          )}
        />
        <AuthenticatedGuard
          exact
          path={PATH.RESEARCH_VARIANCE}
          component={() => (
            <Suspense fallback={<Loader />}>{<ResearchVariance />}</Suspense>
          )}
        />
        <AuthenticatedGuard
          exact
          path={PATH.VARIANCE_UPLOAD_DETAILS}
          component={() => (
            <Suspense fallback={<Loader />}>
              {<VarianceUploadDetails />}
            </Suspense>
          )}
        />

        <AuthenticatedGuard
          exact
          path={PATH.ADJUSTMENT_SUMMARY_REPORT}
          component={() => (
            <Suspense fallback={<Loader />}>
              {<AdjustmentSummaryReport />}
            </Suspense>
          )}
        />
        <AuthenticatedGuard
          exact
          path={PATH.RESET_THE_INVENTORY}
          component={() => (
            <Suspense fallback={<Loader />}>
              {<ResetTheInventoryData />}
            </Suspense>
          )}
        />
        <AuthenticatedGuard
          exact
          path={PATH.TAG_DETAILS_HISTORY}
          component={() => (
            <Suspense fallback={<Loader />}>
              <TagDetailsHistory />
            </Suspense>
          )}
        />
        <AuthenticatedGuard
          exact
          path={PATH.TEAM_TAG_DETAILS_HISTORY}
          component={() => (
            <Suspense fallback={<Loader />}>
              <TagDetailsHistory />
            </Suspense>
          )}
        />
        <AuthenticatedGuard
          exact
          path={PATH.AUDIT_TAG_DETAILS_HISTORY}
          component={() => (
            <Suspense fallback={<Loader />}>
              <TagDetailsHistory />
            </Suspense>
          )}
        />
        <AuthenticatedGuard
          exact
          path={PATH.PERSON_TAG_DETAILS_HISTORY}
          component={() => (
            <Suspense fallback={<Loader />}>
              <TagDetailsHistory />
            </Suspense>
          )}
        />
        <AuthenticatedGuard
          exact
          path={PATH.RESEARCH_VARIANCE_TAG_DETAILS_HISTORY}
          component={() => (
            <Suspense fallback={<Loader />}>
              <TagDetailsHistory />
            </Suspense>
          )}
        />
        <AuthenticatedGuard
          exact
          path={PATH.CUSTOMER_SATISFACTION_SURVEY}
          component={() => (
            <Suspense fallback={<Loader />}>
              <CustomerSatisfactionSurvey />
            </Suspense>
          )}
        />
        <AuthenticatedGuard
          exact
          path={PATH.CREATE_TAGS_BULK}
          component={() => (
            <Suspense fallback={<Loader />}>
              <TagUploadContainer />
            </Suspense>
          )}
        />
        <AuthenticatedGuard
          exact
          path={PATH.PAGE_NOT_FOUND}
          component={() => (
            <Suspense fallback={<Loader />}>
              <PageNotFound />
            </Suspense>
          )}
        />
        <AuthenticatedGuard
          isRedirect={true}
          redirectTo={PATH.PAGE_NOT_FOUND}
          component={() => <></>}
        />
      </Switch>
      {shouldFooterRender() ? <Footer /> : null}
    </>
  )
}
