import React from "react"
import { Grid } from "@mui/material"
import { useTranslation } from "react-i18next"
import { Translates } from "src/i18n/i18n"
import TagIcon from "../../../src/assets/images/TagIcon.svg"
import { renderAccuracySmiley, renderDownTimeSmiley } from "./teamHelper"
import { showValuesWithCurrency } from "src/utils/helper"
import useDollar from "src/hooks/useDollar"
interface TeamSummary {
  teamSummary: any
}
const TeamSummaryBanner: React.FC<TeamSummary> = ({ teamSummary }) => {
  const { t } = useTranslation()
  const { qtyCurrencyLable } = useDollar()
  let roundedAph = Math.round(teamSummary.aph)
  const roundedTeamPercentage = Math.round(
    (teamSummary.aph / teamSummary.goal) * 100
  )
  const totalPercentageValue = 100
  const halfPercentageValue = 50
  return (
    <>
      <Grid container className="tag-list blue-bg mb-2">
        <Grid item sm={9} md={10} lg={10} className="tag-list__cont">
          <img src={TagIcon} alt="tag icon" className="tag-list__icon" />
          <div className="tag-list__col">
            <span className="tag-list__col-title">
              {t(Translates.Total_Active_People)}:
            </span>
            <strong className="tag-list__col-value">
              {teamSummary.totalActivePeople
                ? teamSummary.totalActivePeople
                : "-"}
            </strong>
          </div>
          <div className="tag-list__col">
            <span className="tag-list__col-title">
              {t(Translates.PIECES_PER_HOUR)}:
            </span>
            <strong className="tag-list__col-value">
              <strong className="tag-list__col-value">
                {showValuesWithCurrency(
                  Math.round(Number(teamSummary.piecesPerHour)),
                  0,
                  qtyCurrencyLable
                )}
              </strong>
            </strong>
          </div>
          <div className="tag-list__col">
            <span className="tag-list__col-title">
              {t(Translates.Accuracy)}:
            </span>
            <strong className="tag-list__col-value">
              {renderAccuracySmiley(
                teamSummary.accuracy,
                teamSummary.aGoal,
                teamSummary.aSecondaryGoal
              )}
            </strong>
          </div>

          <div className="tag-list__col">
            <span className="tag-list__col-title">
              {t(Translates.Downtime)}:
            </span>
            <strong className="tag-list__col-value">
              {renderDownTimeSmiley(
                teamSummary.downtime,
                teamSummary.dGoal,
                teamSummary.dSecondaryGoal
              )}
            </strong>
          </div>
        </Grid>
        <Grid item sm={3} md={2} lg={2} className="tag-list__cont text-right">
          <span className="tag-list__col-title d-block text-left">
            {t(Translates.APH)}
          </span>
          <div className="team-efficiency-bar mt-1">
            <span className="team-efficiency-bar__red"></span>
            <span className="team-efficiency-bar__orange"> </span>
            <span className="team-efficiency-bar__green"></span>

            <span
              className="team-efficiency-bar__line"
              style={{
                left:
                  roundedTeamPercentage > totalPercentageValue
                    ? `${totalPercentageValue}%`
                    : roundedTeamPercentage + "%"
              }}
            >
              <label
                className={
                  roundedTeamPercentage > halfPercentageValue ? "text-left" : ""
                }
              >
                {showValuesWithCurrency(roundedAph, 0, qtyCurrencyLable)}
              </label>
            </span>
          </div>
        </Grid>
      </Grid>
    </>
  )
}
export default TeamSummaryBanner
