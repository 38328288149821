import { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { getConfig } from "../redux/slices/configurationSlice"
import { RootState, useAppThunkDispatch } from "../redux/store"
import { decryptUrlParams, getQueryParam } from "src/utils/helper"
import { VARIANCE_TYPE } from "src/utils/constants"

function useDollar() {
  const config: any = useSelector((state: RootState) => state.config)
  const [dollarLable, setDollarLable] = useState("")
  const [dataDecimalLable, setDataDecimalLable] = useState("")
  const [qtyDecimalLable, setQtyDecimalLable] = useState("")
  const [qtyCurrencyLable, setQtyCurrencyLable] = useState("")
  const [priceDecimalLable, setPriceDecimalLable] = useState("")
  const [priceCurrencyLable, setPriceCurrencyLable] = useState("")
  const [isFullVariance, setIsFullVariance] = useState(false)
  const [isExtVarianceAllowed, setIsExtVarianceAllowed] = useState(false)
  const [isBlindVarianceEnabled, setIsBlindVarianceEnabled] = useState(false)
  const [isBVarianceForFullEnabled, setIsBVarianceForFullEnabled] =
    useState(false)
  const [isBVarianceForGroupEnabled, setIsBVarianceForGroupEnabled] =
    useState(false)
  const [isBothVarianceEnabled, setIsBothVarianceEnabled] = useState(false)
  const dispatch = useAppThunkDispatch()
  useEffect(() => {
    checkDollarLable()
  }, [config])
  const checkDollarLable = () => {
    if (!config?.configData) {
      return
    }
    if (
      !Object.keys(config.configData).length &&
      !["cancelled", "loading"].includes(config.status)
    ) {
      dispatch(getConfig())
    }
    setDollarLable(getState())
    setDataDecimalLable(getData())
    setQtyDecimalLable(getQtyDecimalPlaces())
    setQtyCurrencyLable(getQtyCurrencySymbol())
    setPriceDecimalLable(getPriceDecimalPlaces())
    setPriceCurrencyLable(getPriceCurrencySymbol())
    setIsFullVariance(getIsFullVariance())
    setIsExtVarianceAllowed(getIsExtVarianceAllowed())
    setIsBVarianceForFullEnabled(getIsBVarianceFullEnabled())
    setIsBVarianceForGroupEnabled(getIsBVarianceGroupEnabled())
    setIsBlindVarianceEnabled(getIsBlindVarianceEnabled())
    setIsBothVarianceEnabled(checkBothVarianceEnabled())
  }
  const getState = () => {
    return config.configData.Fields?.length
      ? config.configData.Fields[0]?.Ext?.CurrencySymbol
      : ""
  }

  const getData = () => {
    return config.configData.Fields?.length
      ? config.configData.Fields[0]?.Ext?.DecimalPlaces
      : ""
  }
  const getQtyDecimalPlaces = () => {
    return config.configData.Fields?.length
      ? config.configData.Fields[0]?.Qty?.DecimalPlaces
      : ""
  }
  const getQtyCurrencySymbol = () => {
    return config.configData.Fields?.length
      ? config.configData.Fields[0]?.Qty?.CurrencySymbol
      : ""
  }
  const getPriceDecimalPlaces = () => {
    return config.configData.Fields?.length
      ? config.configData.Fields[0]?.Price?.DecimalPlaces
      : ""
  }
  const getPriceCurrencySymbol = () => {
    return config.configData.Fields?.length
      ? config.configData.Fields[0]?.Price?.CurrencySymbol
      : ""
  }
  // Checking only full Variance is enabled from config
  const getIsFullVariance = () => {
    if (Object.keys(config.configData).length) {
      if (config.configData.FullStoreVariances?.[0]?.Enabled) {
        return true
      }
      return false
    } else {
      return false
    }
  }
  const getIsExtVarianceAllowed = () => {
    if (config.configData.Fields?.length) {
      return config?.configData?.Fields[0]?.Ext?.Enabled
    }
    return false
  }

  // Checking Blind Variance is enabled for full Variance
  const getIsBVarianceFullEnabled = () => {
    if (Object.keys(config.configData).length) {
      return config.configData.FullStoreVariances?.[0]?.BlindVariance
    } else {
      return false
    }
  }
  // Checking Blind Variance is enabled for group Variance
  const getIsBVarianceGroupEnabled = () => {
    if (Object.keys(config.configData).length) {
      return config.configData.GroupedVariances?.[0]?.BlindVariance
    } else {
      return false
    }
  }
  //  Checking Blind Variance is enabled for full Variance or Group Variance for Research Variance page
  const getIsBlindVarianceEnabled = () => {
    const varianceGroup = decryptUrlParams(
      getQueryParam(VARIANCE_TYPE?.VARIANCEGROUP)
    )
    if (Object.keys(config.configData).length) {
      if (
        varianceGroup
          ?.toLowerCase()
          ?.includes(VARIANCE_TYPE?.FULL?.trim().toLowerCase())
      ) {
        return getIsBVarianceFullEnabled()
      } else {
        return getIsBVarianceGroupEnabled()
      }
    } else return false
  }
  // Checking if both variances are enabled from config
  const checkBothVarianceEnabled = () => {
    if (Object?.keys(config.configData)?.length) {
      if (
        config.configData.GroupedVariances?.[0]?.Enabled &&
        config.configData.FullStoreVariances?.[0]?.Enabled
      ) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }
  return {
    dollarLable,
    dataDecimalLable,
    qtyDecimalLable,
    qtyCurrencyLable,
    priceDecimalLable,
    priceCurrencyLable,
    isFullVariance,
    isExtVarianceAllowed,
    isBlindVarianceEnabled,
    isBVarianceForFullEnabled,
    isBVarianceForGroupEnabled,
    isBothVarianceEnabled
  }
}

export default useDollar
