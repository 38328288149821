/* eslint-disable no-console */
import type {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse
} from "axios"
import axios from "axios"
import { config } from "../config/config"
import { CUSTOM_REQUEST_HEADERS } from "../utils/constants"
import { toast } from "react-toastify"
import { apiCancellationLogic, logOut, parseToken, translate } from "./helper"

// Fetching new access token based on refresh token  API Call
function refreshTokenAPI() {
  const accessToken: any = localStorage.getItem("accessToken")
  const refreshToken: any = localStorage.getItem("refreshToken")
  const payload = {
    accessToken,
    refreshToken
  }

  return axios
    .create({ baseURL: config.baseURL })
    .post("/api/Login/GetRefreshToken", payload)
    .then((res: AxiosResponse) => {
      if (res?.data?.accessToken && res?.data?.refreshToken) {
        localStorage.setItem("accessToken", res?.data?.accessToken)
        localStorage.setItem("refreshToken", res?.data?.refreshToken)
        return res.data.accessToken
      } else {
        logOut()
        throw new Error("Access token or refresh token not found in response")
      }
    })
    .catch(err => {
      logOut()
      throw err
    })
}

class ApiService<T> {
  service: AxiosInstance
  cancelTokenSources: any = {}
  constructor(_tedUrl?: boolean) {
    const url: string = config.baseURL
    this.service = axios.create({
      baseURL: url
    })

    this.cancelTokenSources = {}
    // request interceptor
    this.service.interceptors.request.use(
      this.requestHandleSuccess,
      this.requestHandleError
    )
    // response interceptor
    this.service.interceptors.response.use(
      this.responseHandleSuccess,
      this.responseHandleError
    )
  }
  scope = this

  post(path: string, payLoad: T): Promise<AxiosResponse> {
    const returnValues = apiCancellationLogic(
      `${path}${JSON.stringify(payLoad)}`,
      this.cancelTokenSources
    )
    this.cancelTokenSources = returnValues.cancelTokenSources
    const propertyName = returnValues.propertyName
    return this.service.post<AxiosResponse<T>>(path, payLoad, {
      cancelToken: this.cancelTokenSources
        ? this.cancelTokenSources[propertyName].token
        : ""
    })
  }
  get(path: string, options: any): Promise<AxiosResponse> {
    return this.service.get(path, options)
  }

  saveWithParams(
    path: string,
    payLoad: object,
    params: object
  ): Promise<AxiosResponse> {
    return this.service.post(path, payLoad, params)
  }

  savefile(
    path: string,
    formData: object,
    config: object
  ): Promise<AxiosResponse> {
    this.service.defaults.headers.common["Content-Type"] = "multipart/form-data"
    return this.service.post(path, formData, config)
  }

  update(path: string, payLoad: object): Promise<AxiosResponse> {
    return this.service.put(path, payLoad)
  }

  findByParams(path: string, params: object): Promise<AxiosResponse> {
    return this.service.get(path, params)
  }

  findAll(path: string): Promise<AxiosResponse> {
    const returnValues = apiCancellationLogic(path, this.cancelTokenSources)
    this.cancelTokenSources = returnValues.cancelTokenSources
    const propertyName = returnValues.propertyName
    return this.service.get(path, {
      cancelToken: this.cancelTokenSources
        ? this.cancelTokenSources[propertyName].token
        : ""
    })
  }

  delete(path: string, params: object): Promise<AxiosResponse> {
    return this.service.delete(path, params)
  }

  setCustomHeaders(key: string, value: any) {
    this.service.defaults.headers.common[key] = value
  }

  async requestHandleSuccess(
    config: AxiosRequestConfig
  ): Promise<AxiosRequestConfig> {
    let access_token: any | null = localStorage.getItem("accessToken")
    if (config.url && !config.url.toLowerCase().includes("login") && access_token) {
      let token = parseToken(access_token)
      let exp = token?.exp
      let current_time = Math.floor(Date.now() / 1000)
      if (!access_token || current_time > exp) {
        const new_access_token = await refreshTokenAPI()
        access_token = new_access_token ? new_access_token : access_token
      }
      config.headers = {
        ...config.headers,
        ...CUSTOM_REQUEST_HEADERS
      }
    }
    config.headers = {
      Authorization: access_token ? `Bearer ${access_token}` : ""
    }
    const newConfig: AxiosRequestConfig = {
      ...config,
      ...{ headers: { ...config.headers } }
    }
    return newConfig
  }

  requestHandleError(error: AxiosError) {
    console.log("errror ------->> ", error)
    return Promise.reject(error)
  }

  responseHandleSuccess(response: AxiosResponse) {
    return response
  }
  setTokens(accessToken: string, refreshToken: string) {
    if (accessToken?.length) localStorage.setItem("accessToken", accessToken)
    if (refreshToken?.length) localStorage.setItem("refreshToken", refreshToken)
  }
  clearTokens() {
    localStorage.clear()
  }

  responseHandleError(error: AxiosError) {
    let originalReq: any = error.config
    const TIMEOUT: number = 100
    if (originalReq) {
      originalReq._retry = true
      let errorMsg: string = ""
      const IGNORE_TOASTER: string = "already has counted items recorded"
      const IGNORE_TOASTER_RANGE: string = "this range overlaps with"
      const IGNORE_TOASTER_COUNTED_RECORDS: string =
        "already has counted records"
      const IGNORE_ACCESS_TOKEN_EXPIRED: string = "access token expired"

      const INVALID_PASSWD: string = "Invalid Password"
      switch (error.response?.data.StatusCode) {
        case 400:
        case 500:
          if (error.response?.data.Message)
            errorMsg = error.response?.data.Message
          break
        case 401:
          if (error.response?.data.Message)
            if (
              !error.response?.data.Message.toLowerCase().includes(
                IGNORE_ACCESS_TOKEN_EXPIRED
              )
            )
              errorMsg = error.response?.data.Message
          if (errorMsg !== INVALID_PASSWD) {
            localStorage.clear()
            setTimeout(() => {
              window.location.href = "/login"
            }, TIMEOUT)
          }
          break
        case 403:
        case 409:
          if (error.response?.data.Message)
            if (
              !error.response?.data.Message.toLowerCase().includes(
                IGNORE_TOASTER
              ) &&
              !error.response?.data.Message.toLowerCase().includes(
                IGNORE_TOASTER_RANGE
              ) &&
              !error.response?.data.Message.toLowerCase().includes(
                IGNORE_TOASTER_COUNTED_RECORDS
              )
            )
              errorMsg = error.response?.data.Message

          console.log("requested resource not found")
          break
        case 404:
          if (
            error.response?.data.Message != "Changed Location not found" &&
            error.response?.data.Message != "Location not found" &&
            !error.response?.data.Message.toLowerCase().includes("not found") &&
            !error.response?.data.Message.toLowerCase().includes(
              "is voided. enter a valid"
            ) &&
            !error.response?.data.Message.toLowerCase().includes(
              "variance group"
            ) &&
            !error.response?.data.Message.toLowerCase().includes("batches")
          ) {
            errorMsg = error.response?.data.Message
            window.location.href = "/PageNotFound"
          }
          console.log("requested resource not found")
          break
        default:
          break
      }
      if (
        (originalReq?.url.startsWith("api") ||
          originalReq?.url.startsWith("/api")) &&
        errorMsg &&
        !["undefined", "null"].includes(errorMsg)
      ) {
        let _errorString = errorMsg.split(" ").join("_")

        const temp = translate(_errorString)
          ? translate(_errorString)
          : errorMsg
        toast.error(`${temp}`, {
          toastId: errorMsg.split("").join("-")
        })
      }
      return Promise.reject(error.response?.data)
    }
    return Promise.reject(error?.message)
  }
}
export default ApiService
